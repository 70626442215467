import { Masks } from "@megapolis/uikit";

export const dateMask = [
  Masks.digit,
  Masks.digit,
  ".",
  Masks.digit,
  Masks.digit,
  ".",
  Masks.digit,
  Masks.digit,
  Masks.digit,
  Masks.digit,
];

export const dateRangeMask = [...dateMask, "-", ...dateMask];
