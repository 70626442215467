import React, { useEffect } from "react";
import { CloseButton } from "components";
import { useDispatch, useSelector } from "react-redux";
import { changeCorrelationDayAC, timeUpdateIsActiveAC } from "old-store/view/action-creators";
import { GState } from "documentations";
import moment from "moment";
import {
  BodyTable,
  BodyTitle,
  Cell,
  HeaderTitle,
  Label,
  Message,
  PopupBody,
  PopupContainer,
  PopupHeader,
  Row,
  StatusBlock,
} from "./history-travel-popup.styled";
import { Tag } from "components";
import { labelsWithValues, useGetParams } from "./hooks";
import TrafficStatus from "../../ctrl-traffic/components/traffic-status";
import { IDZPrediction } from "../../ctrl-idz/components/idz-prediction";

const detectorScoreSelector = (state: GState) => state.roadDetector.detectorScore;
const detectorIDZSelector = (state: GState) => state.roadDetector.detectorIDZ;

export const HistoryTravelPopup = () => {
  const dispatch = useDispatch();
  const { selectedTime } = useSelector((state: GState) => state.view);
  const { values, isFutureIndexes, isLoad, dynamicsMessages } = useGetParams();

  const { dynamicsId, dynamicsMessage, prevScoreParams, scoreParams } = useSelector(detectorScoreSelector);

  const { dynamicsId: dynamicsIdIDZ, dynamicsMessage: dynamicsMessageIDZ } = useSelector(detectorIDZSelector);

  const averageSpeed = scoreParams?.averageSpeed === undefined ? 0 : scoreParams.averageSpeed;
  const prevAverageSpeed = prevScoreParams?.averageSpeed === undefined ? 0 : prevScoreParams.averageSpeed;

  const handleClickCloseButton = () => {
    dispatch(timeUpdateIsActiveAC(false));
  };

  const isPastFuture = isFutureIndexes.includes(0);
  const isNowFuture = isFutureIndexes.includes(1);
  const isFutureFuture = isFutureIndexes.includes(2);

  useEffect(() => {
    return () => {
      const sevenDaysAgo = moment().subtract(7, "days").format("YYYY-MM-DDT08:00:00");
      dispatch(changeCorrelationDayAC(sevenDaysAgo));
    };
  }, []);

  return (
    <PopupContainer>
      <PopupHeader>
        <HeaderTitle>История и прогноз показателей транспортного потока</HeaderTitle>
        <CloseButton onClick={handleClickCloseButton} />
      </PopupHeader>
      <PopupBody>
        <BodyTitle>{moment(selectedTime).format("DD.MM.YYYY HH:mm")}</BodyTitle>
        <BodyTable>
          <Row>
            <div />
            <Cell isFuture={isPastFuture}>
              <Label>На час раньше {isPastFuture && " (прогноз)"}</Label>
            </Cell>
            <Cell isFuture={isNowFuture}>
              <Label>Сейчас {isNowFuture && " (прогноз)"}</Label>
            </Cell>
            <Cell isFuture={isFutureFuture}>
              <Label>На час позже {isFutureFuture && " (прогноз)"}</Label>
            </Cell>
          </Row>
          {labelsWithValues.map((v, index) => (
            <Row key={v.value} withBorder={index !== labelsWithValues.length - 1}>
              <Label>
                {v.label}
                {v.value === "averageDensityArray" && (
                  <Tag title="Приведённая к легковым ТС" className="history-travel-popup-tag">
                    легк.
                  </Tag>
                )}
                {v.value === "averageVolumeArray" && (
                  <Tag title="Приведённая к легковым ТС" className="history-travel-popup-tag">
                    легк.
                  </Tag>
                )}
                {v.value === "scoreArray" && <Message>{dynamicsMessages?.scoreMSG}</Message>}
                {v.value === "alternativeScoreArray" && <Message>{dynamicsMessages?.alternativeScoreMSG}</Message>}
              </Label>
              {!isLoad &&
                values[v.value].map((item) => {
                  return (
                    <Cell isFuture={moment().diff(item.time) < 0}>
                      {item.value}
                      {v.value === "alternativeScoreArray" && moment(selectedTime).diff(item.time) === 0 && (
                        <StatusBlock left={57}>
                          <IDZPrediction dynamicsID={dynamicsIdIDZ} dynamicsMessage={dynamicsMessageIDZ} />
                        </StatusBlock>
                      )}
                      {v.value === "scoreArray" && moment(selectedTime).diff(item.time) === 0 && (
                        <StatusBlock>
                          <TrafficStatus
                            dynamicsID={dynamicsId}
                            dynamicsMessage={dynamicsMessage}
                            averageSpeed={averageSpeed}
                            prevAverageSpeed={prevAverageSpeed}
                          />
                        </StatusBlock>
                      )}
                    </Cell>
                  );
                })}
            </Row>
          ))}
        </BodyTable>
        <BodyTitle>История и прогноз пробок</BodyTitle>
      </PopupBody>
    </PopupContainer>
  );
};
