import { createEffect } from "effector/effector.umd";
import { getGraphStatistics, getGraphVersions, GraphTypes, VersionDate } from "api";
import { State, HistoryGraphVersion } from "./graph-compare.types";

const fetchGraphByDate = createEffect(
  async ({ date, graphType }: { date: string; graphType: State["selectedGraphTypes"] }) => {
    let dataRoad;
    let dataPedestrian;
    if (graphType.road) {
      dataRoad = { ...(await getGraphStatistics(date)), date };
    }
    if (graphType.pedestrian) {
      dataPedestrian = { ...(await getGraphStatistics(date, true)), date };
    }

    if (dataRoad && dataPedestrian) {
      return {
        date,
        edgesCount: dataRoad.edgesCount + dataPedestrian.edgesCount,
        nodesCount: dataRoad.nodesCount + dataPedestrian.nodesCount,
        roadsNetworkLength: dataRoad.roadsNetworkLength + dataPedestrian.roadsNetworkLength,
      };
    }

    if (dataRoad && !dataPedestrian) {
      return dataRoad;
    }
    if (!dataRoad && dataPedestrian) {
      return dataPedestrian;
    }
    return { ...(await getGraphStatistics(date)), date };
  }
);

const fetchGraphVersions = createEffect(async (graphTypes?: GraphTypes[]) => {
  const response = await getGraphVersions(graphTypes);

  const toDateString = (date: VersionDate) => {
    const [day, month, year] = date.split(".").map(Number);

    return new Date(year, month - 1, day).toISOString();
  };

  const sortVersions = (a: HistoryGraphVersion, b: HistoryGraphVersion) => {
    if (a.date > b.date) return -1;
    return 1;
  };

  const items = (Object.entries(response) as [VersionDate, GraphTypes[]][]).map<HistoryGraphVersion>(
    ([date, graphTypes]) => ({
      graphTypes,
      date: toDateString(date),
    })
  );

  return items.sort(sortVersions);
});

export const effects = { fetchGraphByDate, fetchGraphVersions };
