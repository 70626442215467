import { AlertWithoutDataBlock, AlertWithoutDataComponent } from "../../detector-popup.styled";
import { OrangeAlarmIcon } from "assets";

export const DetectorNoDataAlert = (props: {
  lastTime?: string;
  tileData?: { id: string; address: string; num: string };
}) => {
  return (
    <AlertWithoutDataBlock>
      <AlertWithoutDataComponent>
        <OrangeAlarmIcon className="warnings-icon" />
        <div className="last-data-active-block not-dragable-zone">Не активен более суток</div>
      </AlertWithoutDataComponent>
    </AlertWithoutDataBlock>
  );
};
