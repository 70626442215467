import React from "react";
import { Line } from "react-chartjs-2";
import { ChartOptions } from "chart.js";

interface LineChartProps {
  data: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      fill: boolean;
      borderColor: string;
    }[];
  };
  y?: {
    stepSize?: number;
    min?: number;
    max?: number;
  };
}

declare module "chart.js" {
  export interface ChartYAxe {
    grace?: string | undefined;
  }
}

export const DetectorGraph: React.FC<LineChartProps> = ({ data, y }) => {
  const options: ChartOptions = {
    animation: { animateRotate: false, animateScale: false },
    legend: {
      display: true,
      position: "bottom",
      align: "start",

      labels: {
        fontColor: "rgba(3, 40, 76, 0.66)",
        fontSize: 10,
        usePointStyle: true, // использовать символы точек вместо квадратов
        boxWidth: 10,
        padding: 20,
      },
    },
    scales: {
      yAxes: [
        {
          position: "right",
          ticks: {
            labelOffset: 10,
            fontSize: 10,
            padding: -15,
            stepSize: y?.stepSize,
            maxTicksLimit: 9,
          },
          grace: "10%",
          gridLines: {
            tickMarkLength: 37,
          },
        },
      ],

      xAxes: [
        {
          ticks: {
            callback: function (value, index) {
              return index % 6 === 0 ? value : "";
            },
            maxRotation: 0,
            labelOffset: 16,
            fontSize: 10,
            padding: -15,
          },
          gridLines: {
            lineWidth: [1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0],
            tickMarkLength: 24,
            borderDash: [5, 5],
          },
        },
      ],
    },
  };
  return (
    <div>
      <Line data={data} options={options} />
    </div>
  );
};
