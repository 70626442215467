import React from "react";
import cn from "classnames";
import { SectorAnalysisCard } from "../selector-analysis-card";
import { AnalysisTotal } from "api/router/model/analysisResponse";

export const SectorAnalysisInfo = (props: {
  type: "current" | "compare";
  total: AnalysisTotal | null;
  isShowCompare: boolean;
  children?: React.ReactNode;
}) => {
  const { type, total, isShowCompare } = props;

  return (
    <div className={cn("sector-analysis-info", type, isShowCompare && "compare-mode")}>
      <SectorAnalysisCard type="speed" freeFlowSpeed={total?.freeFlowSpeed} speed={total?.speed} />
      <SectorAnalysisCard type="time" freeFlowTime={total?.freeFlowTime} time={total?.time} />
      {/*<SectorAnalysisCard*/}
      {/*  type="utilization"*/}
      {/*  utilization={total?.utilization}*/}
      {/*  freeFlowUtilization={total?.utilization}*/}
      {/*/>*/}
      <SectorAnalysisCard type="volume" freeFlowVolume={total?.freeFlowVolume} volume={total?.volume} />
      <SectorAnalysisCard type="occ" freeFlowOcc={total?.freeFlowOcc} occ={total?.occ} />
      <SectorAnalysisCard type="density" density={total?.density} freeFlowDensity={total?.freeFlowDensity} />
      {props.children}
    </div>
  );
};
