import styled from "styled-components";
import { Props } from "./circle-marker.types";

export const CircleMarker = styled.div<Props>`
  min-width: ${({ radius = 4 }) => `${radius}px`};
  min-height: ${({ radius = 4 }) => `${radius}px`};
  max-width: ${({ radius = 4 }) => `${radius}px`};
  max-height: ${({ radius = 4 }) => `${radius}px`};
  border-radius: 50%;
  background-color: ${({ color = "gray" }) => color};
`;
