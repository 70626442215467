import axios, { Canceler } from "axios";
import { LngLat } from "mapbox-gl";
import { APP_ENV } from "app-env";
import { CANCELED_FETCH_MESSAGE } from "api/utils";
import { RouteResponse } from "features/ctrl-route/types";
import { getRouterInstance } from "api";
import * as TYPES from "./model/models";
import { AnalysisResponse } from "./model/analysisResponse";
import { AnalysisInput, IsochronesInput, MatchPointOutput, RouteInput, RouteTimeInput } from "./model/router";

const { CancelToken } = axios;

let routerCancel: Canceler | null = null;
let analysisCurrentCancel: Canceler | null = null;
let analysisCompareCancel: Canceler | null = null;
let attributesCancel: Canceler | null = null;

const routerUrl = "/router";

export const router = {
  mapmatch: {
    /** Привязка точки к графу и геометрии дорог, возвращает OSM Way и ребро графа */
    point: async (location: LngLat) => {
      const url = `${routerUrl}/mapmatch/point`;
      const { data } = await getRouterInstance().post<MatchPointOutput>(url, { location });
      return data;
    },
    /** Привязка трека к графу и геометрии дорог, возвращает максимум информации по привязке */
    attributes: async (model: TYPES.RESTLocationWithTime[]): Promise<any> => {
      const url = `${routerUrl}/mapmatch/attributes`;
      if (attributesCancel) attributesCancel(CANCELED_FETCH_MESSAGE);
      const { data } = await getRouterInstance().post(url, model, {
        cancelToken: new CancelToken((c) => (attributesCancel = c)),
      });
      return data;
    },
  },
  cancelRoute: () => {
    // TODO переделать на стандартное сообщение об отмене
    if (routerCancel) routerCancel("canceled");
  },
  /** Пересчитать время и трафик на пути */
  time: async (input: RouteTimeInput) => {
    const url = `${routerUrl}/time`;
    const { data } = await getRouterInstance().post<RouteResponse>(url, input, {
      cancelToken: new CancelToken((c) => (routerCancel = c)),
    });
    return data;
  },
  /** Поиск пути */
  route: async (input: RouteInput) => {
    const url = `${routerUrl}/route`;

    const { data } = await getRouterInstance().post<RouteResponse>(url, input, {
      cancelToken: new CancelToken((c) => (routerCancel = c)),
    });

    return data;
  },
  /** Поиск пути через детекторы */
  routeDetector: async (input: RouteInput) => {
    const url = `${routerUrl}/route/detector`;

    const { data } = await getRouterInstance().post<RouteResponse>(url, input, {
      cancelToken: new CancelToken((c) => (routerCancel = c)),
    });

    return data;
  },
  /** Изохроны */
  isochrones: async (model: IsochronesInput): Promise<GeoJSON.FeatureCollection> => {
    const url = `${routerUrl}/isochrones`;
    const { data } = await getRouterInstance().post(url, model);
    return data;
  },
  /** Анализ участка */
  analysis: async (model: AnalysisInput, token: string, fetchType: "current" | "compare") => {
    const url = `${routerUrl}/analysis`;

    if (fetchType === "current" && analysisCurrentCancel) analysisCurrentCancel(CANCELED_FETCH_MESSAGE);
    if (fetchType === "compare" && analysisCompareCancel) analysisCompareCancel(CANCELED_FETCH_MESSAGE);

    //! заглушка для model
    // const modelFixture = {
    //     routeID: "6acff239-1cbc-440e-9d6c-1777cd85d0fa",
    //     trafficPeriod: {
    //         from: "2020-12-09T00:00:00.000+03:00",
    //         to: "2020-12-14T00:00:00.000+03:00",
    //     },
    //     trafficTypes: {
    //         externalSystemIDs: [2, 7, 14, 15, 17, 3, 16, 6],
    //     },
    //     days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
    //     withIncidents: true,
    // };
    const modelFixture = { ...model, withIncidents: true };

    // const { data } = await getRouterInstance().post<AnalysisResponse>(url, model, {
    const { data } = await getRouterInstance().post<AnalysisResponse>(url, modelFixture, {
      cancelToken: new CancelToken((c) => {
        if (fetchType === "current") analysisCurrentCancel = c;
        if (fetchType === "compare") analysisCompareCancel = c;
      }),
    });

    return data;
  },
  /** Анализ участка от детектора*/
  analysisDetector: async (model: AnalysisInput, token: string, fetchType: "current" | "compare") => {
    const url = `${routerUrl}/analysis/detector`;

    if (fetchType === "current" && analysisCurrentCancel) analysisCurrentCancel(CANCELED_FETCH_MESSAGE);
    if (fetchType === "compare" && analysisCompareCancel) analysisCompareCancel(CANCELED_FETCH_MESSAGE);

    //! заглушка для model
    // const modelFixture = {
    //     routeID: "6acff239-1cbc-440e-9d6c-1777cd85d0fa",
    //     trafficPeriod: {
    //         from: "2020-12-09T00:00:00.000+03:00",
    //         to: "2020-12-14T00:00:00.000+03:00",
    //     },
    //     trafficTypes: {
    //         externalSystemIDs: [2, 7, 14, 15, 17, 3, 16, 6],
    //     },
    //     days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
    //     withIncidents: true,
    // };
    const modelFixture = { ...model, withIncidents: true };

    // const { data } = await getRouterInstance().post<AnalysisResponse>(url, model, {
    const { data } = await getRouterInstance().post<AnalysisResponse>(url, modelFixture, {
      cancelToken: new CancelToken((c) => {
        if (fetchType === "current") analysisCurrentCancel = c;
        if (fetchType === "compare") analysisCompareCancel = c;
      }),
    });

    return data;
  },
  simulateIncident: (accessToken: string, body: { routeId: string; lat: number; lng: number }) => {
    return fetch(`${APP_ENV.REACT_APP_DTM_ROUTER_API}router/simulate-incident`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .catch(() => console.error("error while fetch route with modeling dtp"));
  },
  getRouteInfoById: (routeId: number) => {
    return fetch(`${APP_ENV.REACT_APP_DTM_ROUTER_METRO}transit/metro/tags/${routeId}`)
      .then((res) => res.json())
      .catch(() => console.error("fetching route info"));
  },
};
