import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import mapboxgl from "mapbox-gl";
import { GState } from "documentations";
import { EVENTS, on } from "observer";
import { NavigationGroupeLayers } from "components";
import { useStoreContext } from "store";
import GraphEdgesLayer from "map-helpers/layers/graph-edges-layer";
import * as consts from "./ctrl-graph.consts";
import { toggleGraphCard } from "./graph-card/graph-card-helper";
import { useGraphCard } from "./graph-card";
import { useCtrlGraphVisibility } from "./use-ctrl-graph-visibility";

import "./styles/ctrl-graph.scss";

let map: mapboxgl.Map;
let layer: GraphEdgesLayer;

on(EVENTS.INIT_MAP, (_map: mapboxgl.Map) => {
  map = _map;
});

export const CtrlGraph = () => {
  const { isVisible, toggle } = useCtrlGraphVisibility();
  const { setIsShowCard, setEdgeInfo } = useGraphCard();
  const { store } = useStoreContext();

  const dateOfGraph = store.graphCompare.hooks.useSelectedHistoryGraphDate() || "";

  const mapInit = useSelector((state: GState) => state.router.mapIsLoaded);

  const addLayer = () => {
    if (isVisible && map) {
      layer?.add();
      toggleGraphCard(map, true, setIsShowCard, setEdgeInfo);
    }
  };

  const removeLayer = () => {
    layer?.remove();
    toggleGraphCard(map, false, setIsShowCard, setEdgeInfo);
  };

  useEffect(() => {
    if (mapInit) {
      layer = new GraphEdgesLayer(map);
      map.on("style.load", () => addLayer());
    }
  }, [mapInit, map]);

  useEffect(() => {
    if (mapInit) {
      if (isVisible) {
        addLayer();
      } else {
        removeLayer();
      }
    }
  }, [isVisible, mapInit]);

  useEffect(() => {
    layer && isVisible && layer.updateDate({ date: dateOfGraph });
  }, [dateOfGraph, isVisible]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    toggle();
  };

  return (
    <div className="ctrl-graph">
      <NavigationGroupeLayers.Item
        iconNormalUrl={consts.buttonProps.iconNormalUrl}
        iconActiveUrl={consts.buttonProps.iconActiveUrl}
        title={consts.buttonProps.title}
        isActive={isVisible}
        onClick={handleClick}
      />
    </div>
  );
};
