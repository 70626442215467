import React, { memo } from "react";
import { useValues, useCallbacks } from "./average-time-checkbox.hooks";
import { TemplateCheckbox } from "../template-checkbox";
import { RouteTimeIcon } from "assets";
import "../template-checkbox/template-checkbox.scss";

const routeTimeIcon = <RouteTimeIcon />;

export const AverageTimeCheckbox = memo(() => {
  const { isShowAverageTime } = useValues();
  const { handleIsShowAverageTimeChange } = useCallbacks();

  return (
    <TemplateCheckbox
      icon={routeTimeIcon}
      id={"average-time-checkbox"}
      title={""}
      label={"Среднее время пути (мин)"}
      checked={isShowAverageTime}
      onChange={handleIsShowAverageTimeChange}
    />
  );
});
