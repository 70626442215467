import { HexColor } from "global";

export enum RoadGraphCategory {
  Motorway,
  Trunk,
  Primary,
  Secondary,
  Tertiary,
  Unclassified,
  Residential,
  ServiceOther,
}

export const RoadGraphColors: Record<RoadGraphCategory, HexColor> = {
  [RoadGraphCategory.Motorway]: "#ff8282",
  [RoadGraphCategory.Trunk]: "#ff80b0",
  [RoadGraphCategory.Primary]: "#ff80df",
  [RoadGraphCategory.Secondary]: "#f080ff",
  [RoadGraphCategory.Tertiary]: "#bb80ff",
  [RoadGraphCategory.Unclassified]: "#bb80ff",
  [RoadGraphCategory.Residential]: "#8c82ff",
  [RoadGraphCategory.ServiceOther]: "#82daff",
};

export const RoadVerticesColor = "#fca94b";

export const CargoGraphColor = "#265921";
