import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { graphSlice } from "features/ctrl-graph/store/slice";
import { GState } from "documentations";
import { VisibilityState } from "utils/hooks";

export const useData = (): VisibilityState => {
  const isVisible = useSelector((state: GState) => state.graph.isCargoActive);
  const dispatch = useDispatch();

  const toggle = React.useCallback(() => {
    dispatch(graphSlice.actions.setIsCargoActive(!isVisible));
  }, [isVisible, dispatch]);

  const show = React.useCallback(() => {
    dispatch(graphSlice.actions.setIsCargoActive(true));
  }, [dispatch]);

  const hide = React.useCallback(() => {
    dispatch(graphSlice.actions.setIsCargoActive(false));
  }, [dispatch]);

  return { isVisible, toggle, hide, show };
};
