import React from "react";
import { Row } from "../row";
import * as utils from "../../sector-analysis-card.utils";
import * as Types from "../../sector-analysis-card.types";

export const Density = (props: Omit<Types.DensityCard, "type">) => (
  <>
    <Row
      title="Средняя"
      value={utils.formatNumber(props.density, "ед./км")}
      deviation={utils.deviation(props.density, props.freeFlowDensity)}
    />
    <Row title="Без пробок" value={utils.formatNumber(props.freeFlowDensity, "ед./км")} />
  </>
);
