import { UDSIcon } from "assets";
import {
  Button,
  HeaderAddress,
  HeaderNameBlock,
  HeaderTitleBlock,
  TitleWithIconBlock,
} from "../../detector-popup.styled";
import { APP_ENV } from "app-env";

export const UdsHeader = (props: { tileData: { id: string[]; address: string; num: string }; isFullMode: boolean }) => {
  const { tileData, isFullMode } = props;

  const handleLinkClick = (id: string) => () => {
    const url = `${APP_ENV.REACT_APP_WAREHOUSE_DOMAIN}detector/${id}`;
    global.open(url, "blank", "noopener");
  };

  return (
    <TitleWithIconBlock>
      <UDSIcon />
      <HeaderTitleBlock>
        <HeaderNameBlock>
          <div className="num">Данные по участку УДС</div>
        </HeaderNameBlock>
        <HeaderAddress isFullMode={isFullMode}> {tileData.address}</HeaderAddress>
        <HeaderAddress isFullMode={isFullMode}>
          Данные от:
          {tileData.id.map((id) => (
            <Button key={id} className="not-dragable-zone" onClick={handleLinkClick(id)}>
              ДТ-{tileData.id}
            </Button>
          ))}
        </HeaderAddress>
      </HeaderTitleBlock>
    </TitleWithIconBlock>
  );
};
