import React, { FC } from "react";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { hooks } from "./graph-compare-body.hooks";
import { GraphCompareFilters } from "./graph-compare-filters";

import "./graph-compare-body.scss";

interface GraphCompareBodyPropsType {
  isGraphOpen: boolean;
}

export const GraphCompareBody: FC<GraphCompareBodyPropsType> = (props) => {
  const { graphVersions, versionsData, events } = hooks.useGetGraphVersionsTools();
  const { isHistoryLoading, versionsHistory, selectedIndex } = hooks.useGraphVersionsHistory();

  const isVersionsMoreThanOne = graphVersions.length > 1;
  const isVersionsMoreOrEqTwo = graphVersions.length >= 2;

  const dateOfVersions = hooks.useMemoVersions({
    versionsData,
    isVersionsMoreThanOne,
    events,
    versionsHistory,
    isHistoryLoading,
    selectedIndex,
  });

  if (!props.isGraphOpen) {
    return null;
  }

  return (
    <div className="body-block">
      <div className="body__grid">
        <div className="title-block body__grid-header">
          <div className="title-block__title">Версия</div>
          <button
            className={`title-block__add-button ${isVersionsMoreOrEqTwo ? "disable" : ""}`}
            title={isVersionsMoreOrEqTwo ? undefined : "Добавить версию графа для сравнения"}
            disabled={isVersionsMoreOrEqTwo}
            onClick={() => events.createVersion()}>
            <PlusIcon />
          </button>
        </div>
        <div className="body__grid-header">Вершины, ед.</div>
        <div className="body__grid-header">Ребра, ед.</div>
        <div className="body__grid-header">Длина УДС, км</div>
        <div />
        {dateOfVersions}
      </div>
      <GraphCompareFilters />
    </div>
  );
};
