import { VectorTileLayer } from "map-helpers/new-layer/vector-tile-layer";
import { CargoGraphColor } from "map-controller/modules/common";

export class CargoGraphLayer extends VectorTileLayer {
  addLayer() {
    const layer: mapboxgl.AnyLayer = {
      "id": this.layerId,
      "type": "line",
      "source": this.sourceId,
      "source-layer": "edges",
      "layout": {
        "line-join": "round",
        "line-cap": "round",
      },
      "paint": {
        "line-color": CargoGraphColor,
        "line-width": 4,
      },
      "filter": ["==", "OSMID", "none"],
    };
    const beforeId = this.getBeforeId();
    this.map.addLayer(layer, beforeId);
  }

  addSource(url: string) {
    this.map.addSource(this.sourceId, {
      type: "vector",
      tiles: [url],
    });
  }

  setFilter(selected: number[]) {
    if (!this.getLayer()) return;
    this.map.setFilter(this.layerId, ["in", ["get", "OSMID"], ["literal", selected]]);
  }
}
