import React, { useMemo } from "react";
import cn from "classnames";
import { Button } from "@megapolis/uikit";
import { Icons } from "@megapolis/uikit/icons";
import { useStoreContext } from "store";
import { CloseButton, Loader } from "components";
import { VersionPicker } from "./version-picker";
import { helpers } from "./helper";

import "./graph-compare-body.scss";

const useGetGraphVersionsTools = () => {
  const { store } = useStoreContext();

  const graphVersions = store.graphCompare.hooks.useGraphVersions();

  const events = store.graphCompare.events;

  const diffBetweenValues = {
    vertexDiff:
      graphVersions.length > 1
        ? helpers.getDiffBetweenTwoNumbers(graphVersions.map((item) => item.vertexesCount))
        : null,
    edgesDiff:
      graphVersions.length > 1 ? helpers.getDiffBetweenTwoNumbers(graphVersions.map((item) => item.edgesCount)) : null,
    lengthRotesDiff:
      graphVersions.length > 1 ? helpers.getDiffBetweenTwoNumbers(graphVersions.map((item) => item.lengthRotes)) : null,
  };

  const versionsData = graphVersions.map((item) => ({
    ...item,
    vertexesCount: item.vertexesCount?.toLocaleString(),
    edgesCount: item.edgesCount?.toLocaleString(),
    lengthRotes: !isNaN(Number(item.lengthRotes)) ? (Number(item.lengthRotes) / 1000)?.toLocaleString() : undefined,
    diffBetweenValues,
  }));

  return {
    graphVersions,
    versionsData,
    events,
  };
};

interface useMemoDateOfVersionsArgsType {
  events: ReturnType<typeof useGetGraphVersionsTools>["events"];
  versionsData: ReturnType<typeof useGetGraphVersionsTools>["versionsData"];
  isVersionsMoreThanOne: boolean;
  versionsHistory: ReturnType<typeof useGraphVersionsHistory>["versionsHistory"];
  isHistoryLoading: ReturnType<typeof useGraphVersionsHistory>["isHistoryLoading"];
  selectedIndex: ReturnType<typeof useGraphVersionsHistory>["selectedIndex"];
}

const useMemoVersions = ({
  versionsData,
  isVersionsMoreThanOne,
  events,
  versionsHistory,
  selectedIndex,
}: useMemoDateOfVersionsArgsType) => {
  return useMemo(() => {
    const dates = versionsData.map((i) => i.date);

    return versionsData.map((version, index) => {
      const { vertexDiff, edgesDiff, lengthRotesDiff } = version.diffBetweenValues;

      const isShowDiff = !version.isLoading && index > 0;
      const vertex = isShowDiff && helpers.getParsDiff(Number(vertexDiff));
      const edges = isShowDiff && helpers.getParsDiff(Number(edgesDiff));
      const length = isShowDiff && helpers.getParsDiff(Number(lengthRotesDiff));
      const selectVersion = () => {
        events.setSelectedHistoryIndex(index);
      };
      const getDiffClassName = (diff: number) => {
        if (diff === 0 || diff === null || !isShowDiff || isNaN(diff)) return null;
        return diff > 0 ? "green" : "red";
      };

      return (
        <React.Fragment key={version.id}>
          <div className="version-block__datepicker-block">
            <VersionPicker
              currentDate={version.date}
              selectedDates={dates}
              historyVersions={versionsHistory}
              onChange={(date) =>
                events.setDateOfVersion({
                  date: date || "",
                  id: version.id,
                })
              }
            />
            {isVersionsMoreThanOne && <CloseButton onClick={() => events.deleteVersionById(version.id)} />}
          </div>
          <div className={cn("statistic-block__value-number", getDiffClassName(Number(edgesDiff)))}>
            <div>{version.vertexesCount}</div>
            {vertex && <div className="statistic-block__diff">{helpers.getParsDiff(Number(vertexDiff))}</div>}
          </div>
          <div className={cn("statistic-block__value-number", getDiffClassName(Number(edgesDiff)))}>
            <div>{version.edgesCount}</div>
            {edges && <div className="statistic-block__diff">{helpers.getParsDiff(Number(edgesDiff))}</div>}
          </div>
          <div className={cn("statistic-block__value-number", getDiffClassName(Number(lengthRotesDiff)))}>
            <div>{version.lengthRotes}</div>
            {length && <div className="statistic-block__diff">{helpers.getParsDiff(Number(lengthRotesDiff))}</div>}
          </div>
          <div className="statistic-block__actions">
            {version.isLoading ? (
              <div className="statistic-block_loader">
                <Loader />
              </div>
            ) : (
              <Button
                onClick={selectVersion}
                className={cn("statistic-block__eye", selectedIndex === index && "selected")}
                content={Button.Content.Icon}
                icon={<Icons.Monochrome.EyeOpen />}
                priority="quaternary"
              />
            )}
          </div>
        </React.Fragment>
      );
    });
  }, [versionsData, isVersionsMoreThanOne, events]);
};

export const useGraphVersionsHistory = () => {
  const {
    store: { graphCompare },
  } = useStoreContext();

  const fetchVersions = graphCompare.hooks.useFetchGraphVersions();
  const versionsHistory = graphCompare.hooks.useGraphVersionsHistory();
  const isHistoryLoading = graphCompare.hooks.useIsVersionHistoryLoading();
  const selectedIndex = graphCompare.hooks.useSelectedHistoryIndex();

  React.useEffect(() => {
    fetchVersions();
  }, [fetchVersions]);

  return { versionsHistory, isHistoryLoading, selectedIndex };
};

export const hooks = {
  useGetGraphVersionsTools,
  useMemoVersions,
  useGraphVersionsHistory,
};
