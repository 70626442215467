import mapboxgl from "mapbox-gl";
import { Layers } from "../../layers";
import { BaseLayer } from "../../base-layer";
import { RouterAPI } from "api/router";
import * as consts from "./pedestrian-graph.consts";

export class Layer extends BaseLayer<any, any> {
  public readonly id = Layers.Identifiers.GRAPH_PEDESTRIAN;

  constructor(map: mapboxgl.Map, date?: string) {
    const tileUrl = RouterAPI.tiles.graph({
      clip: true,
      pedestrian: true,
      date,
    });

    super(map, {
      id: Layers.Identifiers.GRAPH_PEDESTRIAN,
      sourceType: "tile",
      tileUrl,
      beforeId: Layers.Utils.getBeforeId(Layers.Identifiers.GRAPH_PEDESTRIAN, map),
    });

    const layerConfig: mapboxgl.LineLayer = {
      "id": Layers.Identifiers.GRAPH_PEDESTRIAN,
      "type": "line",
      "source": Layers.Identifiers.GRAPH_PEDESTRIAN,
      "source-layer": "edges",
      "layout": {
        "line-join": "round",
        "line-cap": "round",
      },
      "paint": {
        "line-color": consts.LineColor,
        "line-width": consts.LineWidth,
      },
    };

    this.setLayer(layerConfig);
    this.addSource();
    this.addLayer();
  }
}
