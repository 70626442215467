import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Authentication } from "@megapolis/react-auth";
import { OldStoreProvider } from "old-store";
import { Store } from "./store";
import { APP_ENV } from "app-env";
import { App } from "app";
import { EdgesAdditionalAttr } from "./edges-additional-attr";
import { IconsGlobalStyled } from "./styles/IconsGlobalStyled";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme";

import "styles/reset.scss";
import "styles/design-system.scss";
import "styles/app-controls.scss";
import "styles/instruments.scss";
import "mapbox-gl/dist/mapbox-gl.css";
import "@megapolis/uikit/theme/fonts.css";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: APP_ENV.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <Authentication
    authority={APP_ENV.REACT_APP_AUTH}
    scope={APP_ENV.REACT_APP_AUTH_SCOPE}
    client_id={APP_ENV.REACT_APP_AUTH_CLIENT_ID}>
    <ThemeProvider theme={theme()}>
      <OldStoreProvider>
        <Store>
          <IconsGlobalStyled />
          <App />
        </Store>
      </OldStoreProvider>
    </ThemeProvider>
  </Authentication>,
  document.getElementById("root")
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
(window as any).initEdgesAdditionalAttr = function (containerId: string, persistentEdgeId?: string) {
  const node = document.getElementById(containerId);

  if (node && !!persistentEdgeId) ReactDOM.render(<EdgesAdditionalAttr persistentEdgeId={persistentEdgeId} />, node);
};
