import { TitleWithIconBlock, HeaderTitleBlock, HeaderNameBlock, HeaderAddress } from "../../detector-popup.styled";
import { GreenDetectorItem, OrangeDetectorItem } from "assets";
import { overTime } from "../../utils";

export const DetectorHeader = (props: {
  tileData: { id: string; address: string; num: string };
  lastTime?: string;
  isFullMode: boolean;
}) => {
  const { tileData, lastTime, isFullMode } = props;
  const isTimeOver = overTime(lastTime);

  return (
    <TitleWithIconBlock>
      {isTimeOver || !lastTime ? <OrangeDetectorItem /> : <GreenDetectorItem />}
      <HeaderTitleBlock>
        <HeaderNameBlock>
          <div className="num">{tileData.id}</div>
          <div className="name">Детектор</div>
        </HeaderNameBlock>
        <HeaderAddress title={tileData.address} isFullMode={isFullMode}>
          {tileData.address}
        </HeaderAddress>
      </HeaderTitleBlock>
    </TitleWithIconBlock>
  );
};
