import getBeforeId, { GRAPH_SOURCE_ID, GRAPH_VERTICES_ID } from "../order-layers";
import Layer from "./layer";
import { RoadVerticesColor } from "../../map-controller/modules/common";

// const queryFeaturesByNode = (_map, e) => {
//   const width = 2;
//   const height = 2;
//   const features = _map.queryRenderedFeatures(
//     [
//       [e.point.x - width / 2, e.point.y - height / 2],
//       [e.point.x + width / 2, e.point.y + height / 2],
//     ],
//     { layers: [GRAPH_VERTICES_ID] }
//   );
//
//   return features;
// };

const layerId = GRAPH_VERTICES_ID;

export default class GraphVerticesLayer extends Layer {
  constructor(map) {
    super(map, layerId);
    this.map = map;
  }

  addLayer() {
    const layer = {
      "id": layerId,
      "type": "circle",
      "source": GRAPH_SOURCE_ID,
      "source-layer": "nodes",
      "minzoom": 14,
      "paint": {
        "circle-color": RoadVerticesColor,
        "circle-opacity": 1,
        "circle-radius": ["interpolate", ["exponential", 1.5], ["zoom"], 14, 2, 18, 5],
      },
    };
    const beforeId = getBeforeId(layerId, this.map);
    this.map.addLayer(layer, beforeId);
  }
}
