import React, { memo } from "react";
import { useValues, useCallbacks } from "./global-traffic-score-checkbox.hooks";
import { TemplateCheckbox } from "../template-checkbox";
import { CityTrafficIcon } from "assets";
import "../template-checkbox/template-checkbox.scss";

const cityTrafficIcon = <CityTrafficIcon />;

export const GlobalTrafficScoreCheckbox = memo(() => {
  const { isShowGlobalTrafficScore } = useValues();
  const { handleIsShowGlobalTrafficScoreChange } = useCallbacks();

  return (
    <TemplateCheckbox
      icon={cityTrafficIcon}
      id={"global-traffic-score-checkbox"}
      title={""}
      label={"Балл пробок по городу"}
      checked={isShowGlobalTrafficScore}
      onChange={handleIsShowGlobalTrafficScoreChange}
    />
  );
});
