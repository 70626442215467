import { TrafficPeriod, TrafficSet } from "./traffic";
import { RoadClass } from "api/router/model/roadClass";

export enum TrafficTileFilter {
  Highway = "h",
  Transport = "ptr",
  DedicatedLines = "dl",
}

export type TilesInput = {
  from?: string;
  to?: string;
  day?: string;
  period?: TrafficPeriod;
  es?: number[];
  esSet?: TrafficSet;
};

export type RnisTilesInput = {
  telematicDateBegin?: string;
  telematicDateEnd?: string;
  registrieCodes?: string;
};

export type TrafficTilesInput = TilesInput & {
  maxRoadClass?: RoadClass;
  pedestrian?: boolean;
};

export type TrafficDiffTilesInput = TilesInput & { filter?: TrafficTileFilter };
