import moment from "moment";
import { AnalysisDiagramOptions, DiagramDrawLineOptions } from "../../../../types";
import * as math from "../utils/math";
import * as utils from "../utils/canvas";
import * as consts from "../utils/consts";
import { Bar, Points } from "./schemes";

const getTrafficScoreColor = (score: number) => {
  if (score < 0) return "#7f7f7f";
  if (score === 100 || (score >= 0 && score <= 3)) return "#62C400";
  if (score >= 4 && score <= 6) return "#ffcc00";
  if (score >= 7 && score <= 10) return "#ff3333";
  return "#ff3333";
};

// const getTrafficIndexScore = (score: number) => {
//   return "#5834EB";
// };

export class GraphRender {
  protected canvas: HTMLCanvasElement;
  private ctx: CanvasRenderingContext2D;
  protected options: AnalysisDiagramOptions;
  private scale: number = consts.minScale;
  protected offset: number = 0;
  private graphTop = 0;
  private canvasPaddingLeft = 16;
  private averageSpeedColor = "#00b8fc";
  protected getXY: (e: MouseEvent) => { x: number; y: number };
  private trafficScore: Bar;
  // private trafficIndex: Bar;
  private trafficScoreGlobal: Points;
  // private trafficIndexGlobal: Points;

  public get bodyColumnWidth() {
    const { dateKeys } = this.options;
    return Math.round(this.scaledBodyWidth / dateKeys.length);
  }

  public get scaledBodyWidth() {
    return consts.bodyWidth * this.scale;
  }

  public get bodyLeftWithOffset() {
    return consts.bodyLeft + this.offset;
  }

  public get isShowAverageSpeed() {
    return this.options.visibility.isShowAverageSpeed;
  }

  public get isShowAverageTime() {
    return this.options.visibility.isShowAverageTime;
  }

  public get isUnitsCountDiagram() {
    return this.options.visibility.isUnitsCountDiagram;
  }

  public get isUnitsCountGraph() {
    return this.options.visibility.isUnitsCountGraph;
  }

  public get isShowTrafficScore() {
    return this.options.visibility.isShowTrafficScore;
  }

  public get isShowTrafficIndex() {
    return this.options.visibility.isShowTrafficIndex;
  }

  public get isShowGlobalTrafficScore() {
    return this.options.visibility.isShowGlobalTrafficScore;
  }

  public get isShowGlobalTrafficIndex() {
    return this.options.visibility.isShowGlobalTrafficIndex;
  }

  constructor(canvas: HTMLCanvasElement, options: AnalysisDiagramOptions) {
    this.canvas = canvas;
    this.options = options;

    this.ctx = this.initCtx(this.canvas);
    this.getXY = utils.getXY.bind(this, this.canvas);

    this.trafficScore = new Bar(this.ctx, {
      segmentWidth: this.bodyColumnWidth,
      getColor: getTrafficScoreColor,
      scores: this.options.graphData?.points ?? {},
    });

    // this.trafficIndex = new Bar(this.ctx, {
    //   segmentWidth: this.bodyColumnWidth,
    //   getColor: getTrafficIndexScore,
    //   ...mocks1,
    // });

    this.trafficScoreGlobal = new Points(this.ctx, {
      segmentWidth: this.bodyColumnWidth,
      getColor: getTrafficScoreColor,
      scores: this.options.graphData?.points ?? {},
    });

    // this.trafficIndexGlobal = new Points(this.ctx, {
    //   segmentWidth: this.bodyColumnWidth,
    //   getColor: getTrafficIndexScore,
    //   ...mocks4,
    // });
  }

  protected initCtx = (canvas: HTMLCanvasElement) => {
    return utils.setDPI({
      canvas,
      width: consts.canvasWidth,
      height: 140,
    });
  };

  private getMinMaxForGraph(minValue: number, maxValue: number) {
    const range = maxValue - minValue;

    if (range < 1) return { min: minValue - 1 > 0 ? minValue - 1 : 0, max: maxValue + 1 };

    const step = range / consts.graphVerticalTicksCount;

    return { min: minValue - step > 0 ? minValue - step : 0, max: maxValue + step };
  }

  private drawLine({ values, color, min, max }: DiagramDrawLineOptions) {
    this.ctx.save();

    const range = max - min;
    const lastValue = values[values.length - 1];
    const firstValue = values[0];
    if (lastValue && firstValue) values.push(firstValue);

    values.forEach((value, index) => {
      if (!value) return;

      const prevValue = index > 0 ? values[index - 1] : null;
      const nextValue = values[index + 1];
      const offset = value - min;
      const percent = offset / range;
      const height = consts.graphBodyHeight * percent;
      const offsetX = consts.bodyLeft + this.offset + this.bodyColumnWidth * index;
      const offsetY = consts.graphBodyBottom - height;

      this.ctx.strokeStyle = color;
      this.ctx.lineWidth = 2;

      if (typeof prevValue !== "number") {
        this.ctx.beginPath();
        this.ctx.moveTo(offsetX, offsetY);
      } else if (typeof nextValue !== "number") {
        this.ctx.lineTo(offsetX, offsetY);
        this.ctx.stroke();
      } else this.ctx.lineTo(offsetX, offsetY);
    });

    this.ctx.restore();
  }

  private drawGraphAxis = () => {
    this.ctx.strokeStyle = consts.axisColor;
    this.ctx.lineWidth = 1;
    this.ctx.beginPath();
    this.ctx.moveTo(consts.bodyLeft, consts.graphBodyTop);
    this.ctx.lineTo(consts.bodyLeft, consts.graphBodyBottom);
    this.ctx.lineTo(consts.bodyRight, consts.graphBodyBottom);
    this.ctx.lineTo(consts.bodyRight, consts.graphBodyTop);
    this.ctx.stroke();
  };

  private drawGraphGrid = () => {
    if (!this.options) return;
    const { dateKeys, period } = this.options;

    this.ctx.save();

    const gridStepHeight = consts.graphBodyHeight / consts.graphVerticalTicksCount;
    const tickCounts = dateKeys.length;
    const hour = 60 / period;

    for (let i = 0; i < consts.graphVerticalTicksCount; i++) {
      const offsetY = consts.graphBodyTop + i * gridStepHeight;

      this.ctx.strokeStyle = consts.gridColor;
      this.ctx.lineWidth = 1;
      this.ctx.beginPath();
      this.ctx.moveTo(consts.bodyLeft, offsetY);
      this.ctx.lineTo(consts.bodyRight, offsetY);
      this.ctx.stroke();
    }

    for (let i = 0; i < tickCounts; i++) {
      const isHour = i % hour === 0;
      const is3Hour = i % (3 * hour) === 0;
      const isFirst = i === 0;
      const isLast = i === tickCounts;
      const isShowGrid = this.scale > 2 ? isHour : is3Hour;

      if (isFirst) continue;
      if (isLast) continue;
      if (!isShowGrid) continue;

      const offsetX = consts.bodyLeft + this.offset + i * this.bodyColumnWidth;

      this.ctx.strokeStyle = consts.gridColor;
      this.ctx.lineWidth = 1;
      this.ctx.beginPath();
      this.ctx.moveTo(offsetX, consts.graphBodyTop);
      this.ctx.lineTo(offsetX, consts.graphBodyBottom);
      this.ctx.stroke();
    }

    this.ctx.restore();
  };

  private drawGraphHorizontalTicks = () => {
    if (!this.options) return;
    const { dateKeys } = this.options;

    this.ctx.save();

    const tickCounts = dateKeys.length;

    for (let i = 0; i < tickCounts + 1; i++) {
      const offsetX = consts.bodyLeft + this.offset + this.bodyColumnWidth * i;
      const columnsInHour = math.getColumnsInHour(this.options.period);
      const isHour = i % columnsInHour === 0;
      const is3Hour = i % (3 * columnsInHour) === 0;
      const isFirst = i === 0;
      const isLast = i === tickCounts;
      const isShowTime = this.scale > 2 ? isHour : is3Hour;

      if (isHour) {
        this.ctx.strokeStyle = consts.axisColor;
        this.ctx.lineWidth = 1;
        this.ctx.beginPath();
        this.ctx.moveTo(offsetX, consts.graphBodyBottom);
        this.ctx.lineTo(offsetX, consts.graphBodyBottom + consts.tickWidth);
        this.ctx.stroke();
      }

      if (isShowTime && !isFirst && !isLast) {
        const time = moment(dateKeys[i]).format("H:mm");

        this.ctx.fillStyle = consts.defaultTextColor;
        this.ctx.textAlign = "center";
        this.ctx.textBaseline = "middle";
        this.ctx.font = 'normal normal normal 10px "Roboto"';
        this.ctx.fillText(time, offsetX, consts.graphBodyBottom + consts.graphPaddingBottom + 3);
      }
    }

    this.ctx.restore();
  };

  private drawAverageSpeedLine = () => {
    if (!this.options) return;
    const { graphData, dateKeys, graphRange } = this.options;

    if (!graphData || !graphRange) return;

    const { points } = graphData;

    const values = dateKeys.map((date) => {
      const point = points[date];

      if (!point) return null;
      return point.speed;
    });

    const { min, max } = this.getMinMaxForGraph(graphRange.minSpeed!, graphRange.maxSpeed!);

    this.drawLine({
      max,
      min,
      color: consts.averageSpeedColor,
      values,
    });
  };

  private drawAverageTimeLine = () => {
    if (!this.options) return;
    const { graphData, dateKeys, graphRange } = this.options;

    if (!graphData || !graphRange) return;

    const { points } = graphData;

    const values = dateKeys.map((date) => {
      const point = points[date];

      if (!point) return null;

      return point.time / 60;
    });

    const { min, max } = this.getMinMaxForGraph(graphRange.minTime! / 60, graphRange.maxTime! / 60);

    this.drawLine({
      max,
      min,
      color: consts.averageTimeColor,
      values,
    });
  };

  private drawUnitsCountLine = () => {
    if (!this.options) return;
    const { graphData, dateKeys, graphRange } = this.options;

    if (typeof graphRange?.minUnitsCount !== "number" || typeof graphRange.maxUnitsCount !== "number") return;

    if (!graphData || !graphRange) return;

    const { points } = graphData;

    const values = dateKeys.map((date) => {
      const point = points[date];

      if (!point) return null;

      return point.unitCounts;
    });

    const { min, max } = this.getMinMaxForGraph(graphRange.minUnitsCount, graphRange.maxUnitsCount);

    this.drawLine({
      max,
      min,
      color: consts.unitsCountColor,
      values,
    });
  };

  private drawGraphSpeedTicks = () => {
    if (!this.options) return;
    const { graphRange } = this.options;

    if (!graphRange) return;

    const { min, max } = this.getMinMaxForGraph(graphRange.minSpeed!, graphRange.maxSpeed!);
    const range = max - min;
    const speedStep = range / consts.graphVerticalTicksCount;
    const gridStepHeight = consts.graphBodyHeight / consts.graphVerticalTicksCount;

    for (let i = 0; i <= consts.graphVerticalTicksCount; i++) {
      const offsetY = consts.graphBodyTop + i * gridStepHeight;
      this.ctx.strokeStyle = consts.axisColor;
      this.ctx.lineWidth = 1;
      this.ctx.beginPath();
      this.ctx.moveTo(consts.bodyLeft, offsetY);
      this.ctx.lineTo(consts.bodyLeft - consts.tickWidth, offsetY);
      this.ctx.stroke();

      const speed = math.formatGraphTickValue(max - i * speedStep, range);

      this.ctx.fillStyle = consts.defaultTextColor;
      this.ctx.textAlign = "end";
      this.ctx.textBaseline = "middle";
      this.ctx.font = 'normal normal normal 10px "Roboto"';
      this.ctx.fillText(speed, consts.bodyLeft - consts.tickValueOffset, offsetY);
    }
  };

  private drawGraphTimeTicks = () => {
    if (!this.options) return;
    const { graphRange } = this.options;

    if (!graphRange) return;

    const { min, max } = this.getMinMaxForGraph(graphRange.minTime! / 60, graphRange.maxTime! / 60);
    const range = max - min;
    const timeStep = range / consts.graphVerticalTicksCount;
    const gridStepHeight = consts.graphBodyHeight / consts.graphVerticalTicksCount;

    for (let i = 0; i <= consts.graphVerticalTicksCount; i++) {
      const offsetY = consts.graphBodyTop + i * gridStepHeight;

      this.ctx.strokeStyle = consts.axisColor;
      this.ctx.lineWidth = 1;
      this.ctx.beginPath();
      this.ctx.moveTo(consts.bodyRight, offsetY);
      this.ctx.lineTo(consts.bodyRight + consts.tickWidth, offsetY);
      this.ctx.stroke();

      const time = math.formatGraphTickValue(max - i * timeStep, range);

      this.ctx.fillStyle = consts.defaultTextColor;
      this.ctx.textAlign = "start";
      this.ctx.textBaseline = "middle";
      this.ctx.font = 'normal normal normal 10px "Roboto"';
      this.ctx.fillText(time, consts.bodyRight + consts.tickValueOffset, offsetY);
    }
  };

  public drawCrosshair(x: number) {
    if (x <= consts.bodyLeft || x >= consts.bodyRight) return;

    this.ctx.save();
    this.ctx.lineWidth = 1;
    this.ctx.strokeStyle = consts.crossHairColor;
    this.ctx.setLineDash([10, 6]);

    this.ctx.beginPath();
    this.ctx.moveTo(x, consts.graphBodyTop);
    this.ctx.lineTo(x, consts.graphBodyBottom);
    this.ctx.stroke();
    this.ctx.restore();
  }

  private drawStoppers = () => {
    if (!this.ctx) return;

    this.ctx.beginPath();
    this.ctx.fillStyle = "#ffffff";
    this.ctx.fillRect(0, 0, consts.bodyLeft, 140);
    this.ctx.fillRect(consts.bodyRight, 0, consts.bodyLeft, 140);
    this.ctx.closePath();
  };

  private clear = () => {
    this.ctx?.clearRect(0, 0, 1000, 1000);
  };

  public updateOptions = (options: AnalysisDiagramOptions) => {
    this.options = options;
    this.trafficScore.updateScores(this.options.graphData?.points ?? {});
    this.trafficScoreGlobal.updateScores(this.options.graphData?.points ?? {});
    this.draw();
  };

  public updateOffset = (x: number) => {
    this.offset = x;
    this.draw();
  };

  public updateScale = (scale: number) => {
    this.scale = scale;
    this.draw();
  };

  public draw = () => {
    this.clear();
    this.drawGraphAxis();
    this.drawGraphGrid();
    this.drawGraphHorizontalTicks();

    // if (this.isShowTrafficIndex) {
    //   this.trafficIndex.draw(this.scale, this.offset);
    // }

    // if (this.isShowGlobalTrafficIndex) {
    //   this.trafficIndexGlobal.draw(this.scale, this.offset);
    // }

    if (!this.isUnitsCountDiagram && this.isShowTrafficScore) {
      this.trafficScore.draw(this.scale, this.offset);
    }

    if (!this.isUnitsCountDiagram && this.isShowGlobalTrafficScore) {
      this.trafficScoreGlobal.draw(this.scale, this.offset);
    }

    if (!this.isUnitsCountDiagram && this.isShowAverageSpeed) {
      this.drawAverageSpeedLine();
    }

    if (!this.isUnitsCountDiagram && this.isShowAverageTime) {
      this.drawAverageTimeLine();
    }

    if (this.isUnitsCountDiagram && this.isUnitsCountGraph) {
      this.drawUnitsCountLine();
    }

    this.drawStoppers();
    this.drawGraphSpeedTicks();
    this.drawGraphTimeTicks();
  };
}
