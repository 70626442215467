import React from "react";
import * as consts from "../../sector-analysis-card.consts";

export const Row = React.memo((props: { title: string; value: string; deviation?: string }) => {
  return (
    <div className={`${consts.baseClass}-row`}>
      <span className={`${consts.baseClass}-name`}>{props.title}</span>
      <div className={`${consts.baseClass}-value`}>
        <div>
          <span className={`${consts.baseClass}-average`}>{props.value}</span>
          {props.deviation && <span className={`${consts.baseClass}-deviation`}>{props.deviation}</span>}
        </div>
        {/* <span className={`${consts.baseClass}-range`}>({range})</span> */}
      </div>
    </div>
  );
});
