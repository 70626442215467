import { MutableRefObject, useEffect } from "react";
import { MapController } from "map-controller";
import { useStoreContext } from "store";

const usePedestrianControl = (mapController: MutableRefObject<MapController | null>) => {
  const { store } = useStoreContext();
  const isVisible = store.pedestrianGraph.hooks.useIsVisible();
  const date = store.graphCompare.hooks.useSelectedHistoryGraphDate();

  useEffect(() => {
    date && isVisible && mapController.current?.pedestrianGraph?.handleChangeDate(date);
  }, [date, isVisible, mapController]);
};

export const hooks = { usePedestrianControl };
