import React from "react";
import cn from "classnames";
import { formatDate, toDate, month, getMonthName, isDaySame } from "utils";
import * as Types from "../version-picker.types";
import * as consts from "../version-picker.consts";

const getMonth = (date: string) => {
  const d = toDate(date);
  return month(d);
};

const isNextMonth = (currentDate: string, prevMonth: number) => {
  const currentMonth = getMonth(currentDate);
  return prevMonth !== currentMonth;
};

export const List = (props: Types.DropdownProps) => {
  const currentMonth = React.useRef<number>(-1);
  const handleClick = (date: string) => () => {
    props.onChange?.(date);
  };

  return (
    <ul className={consts.listClass}>
      {props.historyVersions.map((item, index) => {
        const isNext = isNextMonth(item.date, currentMonth.current);
        const isActualVersion = index === 0;
        const isSelected = props.selectedDates.some((date) => isDaySame(date, item.date));
        if (index === 0 || isNext) currentMonth.current = getMonth(item.date);

        return (
          <React.Fragment key={item.date}>
            {isNext && <div className={`${consts.listClass}__month`}>{getMonthName(item.date)}</div>}
            <li className={cn(consts.listItemClass, isSelected && `selected`)}>
              <button onClick={handleClick(item.date)}>
                {formatDate(consts.DATE_FORMAT)(item.date)}
                {isActualVersion && " (Актуальная версия)"}
              </button>
            </li>
          </React.Fragment>
        );
      })}
    </ul>
  );
};
