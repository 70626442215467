import React from "react";
import { Icons } from "assets";
import * as Types from "./sector-analysis-card.types";

const SectorAnalysisCardIcon = React.memo((props: { type: Types.AnalysisCardType }) => {
  switch (props.type) {
    case "speed":
      return <Icons.SpeedIcon />;
    case "route":
      return <Icons.BusIcon />;
    case "time":
      return <Icons.TimeIcon />;
    // case "compare":
    //     return <Icons.CompareIcon />;
    case "utilization":
      return <Icons.TrafficJam />;
    case "occ":
      return <Icons.Cursor />;
    case "volume":
      return <Icons.TrafficJam />;
    case "density":
      return <Icons.TrafficFlow />;
    default:
      return <></>;
  }
});

export default SectorAnalysisCardIcon;
