import React from "react";
import mapboxgl, { LngLat, Popup } from "mapbox-gl";
import { MiddlewareAPI } from "redux";
import { GState } from "documentations";
import { DETECTOR_ID, ND_DETECTOR_ID } from "map-helpers/order-layers";
import { popupController, WarehousType } from "map-helpers/popups/popup-controller";
import { on } from "observer";
import { EVENTS } from "observer/events";
import { CreatePopup } from "../types";
import { DetectorLayer, DetectorVolumetricLayer } from "../map-layer";
import { DetectorPopup } from "../componets/detector-popup";
import { APP_ENV } from "app-env";
import { NoneDirectionDetectorLayer } from "../map-layer/none-direction-detector-layer";
import { loadLastDetectorData } from "../store/slice";
import "./detector-tooltip-popup.scss";

const minZoom = 15.5;
const maxPitch = 20;

export class DetectorController {
  private detectorLayer: DetectorLayer;
  private ndDetectorLayer: NoneDirectionDetectorLayer;
  private detectorVolumetricLayer: DetectorVolumetricLayer;
  private activeItems: number[] = [];
  private hoverItemId: number | null = null;
  private fixationsPopup: Popup;
  private fixationsTooltipPopup: Popup;
  private hovered: THREE.Mesh<THREE.CylinderGeometry, THREE.MeshPhongMaterial> | null = null;
  private date: string | null = null;

  public get isVolumetric() {
    return this.map.getZoom() >= minZoom && this.map.getPitch() >= maxPitch;
  }

  constructor(private map: mapboxgl.Map, private store: MiddlewareAPI<any, GState>) {
    this.detectorLayer = new DetectorLayer(map, DETECTOR_ID, DETECTOR_ID);
    this.ndDetectorLayer = new NoneDirectionDetectorLayer(map, ND_DETECTOR_ID, ND_DETECTOR_ID);
    this.detectorVolumetricLayer = new DetectorVolumetricLayer(map);
    this.map.addLayer(this.detectorVolumetricLayer);
    this.detectorLayer.on("click", this.handleClick);
    this.detectorLayer.on("mouseenter", this.handleMouseEnter);
    this.detectorLayer.on("mouseleave", this.handleMouseLeave);
    this.ndDetectorLayer.on("click", this.handleClick);
    this.map.on("style.load", this.updateDetectorLayer);
    this.map.on("style.load", this.updateNDDetectorLayer);
    this.map.on("mousemove", this.handleMousemove);
    this.map.on("pitch", this.handleMapChange);
    this.map.on("zoom", this.handleMapChange);

    this.fixationsPopup = new Popup({
      closeButton: false,
      closeOnClick: false,
      className: "route-length-error-popup__count",
    });

    this.fixationsTooltipPopup = new Popup({
      closeButton: false,
      closeOnClick: false,
      closeOnMove: false,
      className: "detector-tooltip-popup",
      anchor: "top",
    });

    on(EVENTS.MAP_POPUP_ENTER, this.handlePopupEnter);
    on(EVENTS.MAP_POPUP_LEAVE, this.handlePopupLeave);
    this.updateDetectorLayer();
    this.updateNDDetectorLayer();
  }

  public updateDetectorLayer = () => {
    const {
      detector: { isActiveDetector, isActiveNoneDirectionDetector },
    } = this.store.getState();

    this.detectorVolumetricLayer.isVisible = (isActiveNoneDirectionDetector || isActiveDetector) && this.isVolumetric;

    if (isActiveDetector) this.addDetectorLayer();
    if (!isActiveDetector) this.removeDetectorLayer();
  };

  public updateNDDetectorLayer = () => {
    const {
      detector: { isActiveDetector, isActiveNoneDirectionDetector },
    } = this.store.getState();

    this.detectorVolumetricLayer.isVisible = (isActiveNoneDirectionDetector || isActiveDetector) && this.isVolumetric;

    if (isActiveNoneDirectionDetector) this.addNDDetectorLayer();
    if (!isActiveNoneDirectionDetector) this.removeNDDetectorLayer();
  };

  private handleMapChange = () => {
    if (!this.detectorVolumetricLayer) return;
    this.detectorLayer?.setOpacity(!this.isVolumetric);
    this.ndDetectorLayer?.setOpacity(!this.isVolumetric);
    this.detectorVolumetricLayer.isVisible = this.isVolumetric;
  };

  private handleMousemove = (e: mapboxgl.MapMouseEvent) => {
    if (!this.isVolumetric) return;

    const hovered = this.detectorVolumetricLayer?.raycast(e.point)?.[0];
    const properties = this.detectorVolumetricLayer.getProperties(hovered?.object.uuid ?? "");

    if (!hovered) {
      if (this.hovered) {
        this.hovered.material.opacity = 0.5;
      }
      this.hovered = null;
      this.fixationsPopup.remove();
      return this.map.triggerRepaint();
    }

    if (this.hovered) {
      this.hovered.material.opacity = 0.5;
    }

    if (!properties) {
      return this.fixationsPopup.remove();
    }

    (hovered.object as THREE.Mesh<THREE.CylinderGeometry, THREE.MeshPhongMaterial>).material.opacity = 0.7;
    this.hovered = hovered.object as THREE.Mesh<THREE.CylinderGeometry, THREE.MeshPhongMaterial>;
    this.fixationsPopup
      .setHTML(`${properties?.fixationsNum ?? 0} ТС/час`)
      .setLngLat(e.lngLat)
      .addTo(this.map);
    this.map.triggerRepaint();
  };

  private handleClick = (e: mapboxgl.MapMouseEvent, features: mapboxgl.MapboxGeoJSONFeature[]) => {
    const feature = features.shift();
    if (!feature) return;

    const {
      // @ts-ignore
      properties: { Id, Num, address, Lat, Lng, last_time: lastTime },
    } = feature;

    const lngLat = new LngLat(Lng, Lat);
    this.createPopup({ id: Id, num: Num, address, lngLat, lastTime });
    this.setActiveItems(Id, e.point);
  };

  private handleMouseEnter = (e: mapboxgl.MapMouseEvent, features: mapboxgl.MapboxGeoJSONFeature[]) => {
    const feature = features.shift();
    if (!feature) return;

    const {
      // @ts-ignore
      properties: { Lat, Lng },
    } = feature;

    const tooltip = `<div>ДТ-${feature.properties?.Id}</div>`;
    if (Lat && Lng) {
      this.fixationsTooltipPopup?.setLngLat(new mapboxgl.LngLat(Lng, Lat)).setHTML(tooltip).addTo(this.map);
    }
  };

  private handleMouseLeave = (e: mapboxgl.MapMouseEvent, features: mapboxgl.MapboxGeoJSONFeature[]) => {
    const feature = features.shift();
    if (!feature) return;
    this.fixationsTooltipPopup?.remove();
  };

  private handlePopupEnter = ({ id, type }: { id: number; type: WarehousType }) => {
    if (this.isVolumetric || type !== "detector") return;
    this.setHoverFilter(id);
    this.setFilterByActiveItems();
  };

  private handlePopupLeave = ({ type }: { id: number; type: WarehousType }) => {
    if (this.isVolumetric || type !== "detector") return;
    this.setHoverFilter(null);
    this.setFilterByActiveItems();
  };

  private setActiveItems(id: number | null, point?: mapboxgl.Point) {
    const idStr = String(id);
    if (typeof id !== "number") {
      this.activeItems = [];
      this.detectorLayer.setFilterOnActiveLayer(["boolean", false]);
      this.ndDetectorLayer.setFilterOnActiveLayer(["boolean", false]);
      return;
    }
    if (this.activeItems.includes(id)) this.activeItems = this.activeItems.filter((el) => el !== id);
    else this.activeItems.push(id);

    if (this.isVolumetric && point) {
      this.detectorLayer.setFilterOnActiveLayer(["boolean", false]);
      this.ndDetectorLayer.setFilterOnActiveLayer(["boolean", false]);
      if (this.detectorVolumetricLayer?.isSelected(idStr)) {
        return this.detectorVolumetricLayer?.removeSelection(idStr);
      }
      const shapeUnder = this.detectorVolumetricLayer.raycast(point)?.[0];
      if (!shapeUnder) return;
      return this.detectorVolumetricLayer?.select(idStr, shapeUnder.object.position);
    }

    if (this.isVolumetric || !this.activeItems.length) {
      this.detectorLayer.setFilterOnActiveLayer(["boolean", false]);
      this.ndDetectorLayer.setFilterOnActiveLayer(["boolean", false]);
      return;
    }

    this.setFilterByActiveItems();
  }

  private setFilterByActiveItems() {
    const filter = ["any"];
    this.activeItems
      .filter((el) => el !== this.hoverItemId)
      // @ts-ignore
      .forEach((el) => filter.push(["==", ["get", "Id"], el]));
    this.detectorLayer.setFilterOnActiveLayer(["any", filter]);
    this.ndDetectorLayer.setFilterOnActiveLayer(["any", filter]);
  }

  private setHoverFilter(id: number | null) {
    if (typeof id !== "number") {
      this.hoverItemId = null;
      this.detectorLayer.setFilterOnHoverLayer(["boolean", false]);
      this.ndDetectorLayer.setFilterOnHoverLayer(["boolean", false]);
      return;
    }
    this.hoverItemId = id;
    this.detectorLayer.setFilterOnHoverLayer(["==", ["get", "Id"], id]);
    this.ndDetectorLayer.setFilterOnHoverLayer(["==", ["get", "Id"], id]);
  }

  private addDetectorLayer() {
    const url = this.getTileUrl();
    this.detectorLayer.add(url);
  }
  private addNDDetectorLayer() {
    const url = this.getTileUrl(true);
    this.ndDetectorLayer.add(url);
  }

  private removeDetectorLayer() {
    this.detectorLayer.remove();
    popupController?.removePopupByType("detector");
    this.setActiveItems(null);
  }

  private removeNDDetectorLayer() {
    this.ndDetectorLayer.remove();
    popupController?.removePopupByType("detector");
    this.setActiveItems(null);
  }

  private getTileUrl(isNdDetector?: boolean) {
    let filter = "";

    if (isNdDetector) {
      filter =
        "include=assetstatus,assetstatusid,lat,lng,addresses,assetTypeId&filter=not exists(sensorDirections,'exists(osmBinds,''id ge -1'')')&additionalFields=lat,lng";
    } else {
      filter =
        "include=assetstatus,assetstatusid,lat,lng,addresses,assetTypeId&filter=exists(sensorDirections,'exists(osmBinds,''id ge -1'')')&additionalFields=lat,lng";
    }
    const base = `${
      APP_ENV.REACT_APP_DTM_WAREHOUSE_API
    }/assetdetector/layerMVT/{z}/{x}/{y}?${filter}&calculatedVehicleStatistics=true&offsetRightSamePlace=${
      APP_ENV.REACT_APP_SAME_PLACE_OBJECTS_OFFSET || 0
    }`;

    if (typeof this.date === "string") {
      return base + `&dateEndStatistics=${this.date}`;
    }

    return base;
  }

  public setDate = (date: string) => {
    if (this.date !== date) {
      const layer = this.map.getLayer(this.detectorVolumetricLayer.id);
      if (layer) {
        this.map.removeLayer(layer.id);
        this.detectorVolumetricLayer = new DetectorVolumetricLayer(this.map);
        this.detectorVolumetricLayer.isVisible = this.isVolumetric;
        this.map.addLayer(this.detectorVolumetricLayer);
        this.map.triggerRepaint();
      }
    }

    this.date = date;
    this.detectorLayer.setUrl(this.getTileUrl());
    this.ndDetectorLayer.setUrl(this.getTileUrl(true));
  };

  private createPopup({ id, num, address, lngLat, lastTime }: CreatePopup) {
    const containerId = `detector-${id}`;
    const point = this.map.project(lngLat);
    const onClickClose = (e: React.MouseEvent<HTMLButtonElement | SVGSVGElement, MouseEvent>) => {
      if (e.ctrlKey) {
        popupController?.removePopupByType("detector");
        this.setActiveItems(null);
        this.setHoverFilter(null);
      } else {
        popupController?.removePopupById(containerId);
        this.setActiveItems(Number(id), point);
        this.setHoverFilter(null);
      }
    };

    const token = this.store.getState().oidc.user.access_token;

    const { forward } = this.store.getState().detector?.openedDetectors?.[id] || {};

    const component = (
      <DetectorPopup
        id={String(id)}
        num={num}
        address={address}
        onClickClose={onClickClose}
        lastTime={lastTime}
        token={token}
      />
    );
    if (forward) {
      popupController?.addPopup({ component, lngLat, id: containerId, type: "detector", itemId: Number(id) });
    } else {
      this.store.dispatch(loadLastDetectorData({ id, num, address })).then(() => {
        popupController?.addPopup({ component, lngLat, id: containerId, type: "detector", itemId: Number(id) });
      });
    }
  }
}
