import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  margin-top: 8px;
  border-top: 1px solid var(--separator-color-default);
  padding-top: 16px;
  gap: 10px;

  button {
    flex-grow: 1;
    justify-content: flex-start;

    > span:first-child {
      min-width: 24px;
      text-align: center;

      > * {
        display: inline-block;
      }
    }

    > span:last-child {
      color: var(--text-color-main);
      white-space: nowrap;
    }
  }
`;
