import React, { memo, useMemo } from "react";
import { LegendColumn } from "./legend-column";
import { trafficType } from "../sector-analysis-appearance-menu/utils/consts";

import "./sector-analysis-diagram-legend.scss";

const baseClass = "sector-analysis-diagram-legend";

type LegendItem = { from: number; to: number; color: string };

export type Legend = { [key: number]: LegendItem };

type Props = {
  legend: Legend;
};

const useColumns = (legend: Legend) => {
  return useMemo(() => trafficType.map((item, i) => <LegendColumn key={i} {...legend[item]} />), [legend]);
};

export const SectorAnalysisDiagramLegend: React.FC<Props> = memo(({ legend }) => {
  const columns = useColumns(legend);

  return (
    <div className={baseClass}>
      <div className={`${baseClass}__header`}>
        <span>Средняя скорость / скорость без пробок</span>
      </div>
      <div className={`${baseClass}__content`}>{columns}</div>
    </div>
  );
});
