import React from "react";
import { NavigationGroupeLayers } from "components";
import { useData } from "./cargo-graph-button.hooks";
import * as consts from "./cargo-graph-button.consts";

export const CargoGraphButton = () => {
  const { isVisible, toggle } = useData();

  const handleClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      toggle();
    },
    [toggle]
  );

  return (
    <NavigationGroupeLayers.Item
      iconNormalUrl={consts.buttonProps.iconNormalUrl}
      iconActiveUrl={consts.buttonProps.iconActiveUrl}
      title={consts.buttonProps.title}
      isActive={isVisible}
      onClick={handleClick}
    />
  );
};
