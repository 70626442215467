import { RouterAPI } from "api/router";
import { isToday } from "utils";
import getBeforeId, { GRAPH_EDGES_ID, GRAPH_SOURCE_ID } from "../order-layers";
import Layer from "./layer";
import GraphArrowLayer from "./graph-arrow-layer";
import { APP_ENV } from "../../app-env";
import GraphEdgesHighlightLayer from "./graph-edges-highlight-layer";
import GraphVerticesHighlightLayer from "./graph-vertices-highlight-layer";
import GraphVerticesLayer from "./graph-vertices-layer";
import { RoadGraphCategory, RoadGraphColors } from "../../map-controller/modules/common";

const layerId = GRAPH_EDGES_ID;
const isOSMGraph = Boolean(APP_ENV.REACT_APP_DTM_ROUTER_GRAPH_API.includes("graph-osm"));

const graphColor = (category) => [
  category,
  RoadGraphColors[category] ?? RoadGraphColors[RoadGraphCategory.Unclassified],
];

export default class GraphEdgesLayer extends Layer {
  constructor(map) {
    super(map, layerId);
    this.map = map;
    this.edgeHighlightLayer = new GraphEdgesHighlightLayer(map);
    this.verticesHighlighLayer = new GraphVerticesHighlightLayer(map);
    this.vericesLayer = new GraphVerticesLayer(map);
    this.arrowLayer = new GraphArrowLayer(map);
  }

  addLayer() {
    const routeGraphEdgesLayer = {
      "id": GRAPH_EDGES_ID,
      "type": "line",
      "source": GRAPH_SOURCE_ID,
      "source-layer": "edges",
      "layout": {
        "line-join": "round",
        "line-cap": "round",
      },
      "paint": {
        "line-color": [
          "match",
          ["get", "classification"],
          ...graphColor(RoadGraphCategory.Motorway),
          ...graphColor(RoadGraphCategory.Trunk),
          ...graphColor(RoadGraphCategory.Primary),
          ...graphColor(RoadGraphCategory.Secondary),
          ...graphColor(RoadGraphCategory.Tertiary),
          ...graphColor(RoadGraphCategory.Unclassified),
          ...graphColor(RoadGraphCategory.Residential),
          ...graphColor(RoadGraphCategory.ServiceOther),
          RoadGraphColors[RoadGraphCategory.ServiceOther],
        ],
        "line-width": isOSMGraph
          ? 4
          : [
              "interpolate",
              ["exponential", 1.5],
              ["zoom"],
              5,
              ["match", ["get", "oneWay"], 0, 0.75, 1, 1.5, 0],
              12,
              ["match", ["get", "oneWay"], 0, 1.5, 1, 3, 0],
              18,
              ["match", ["get", "oneWay"], 0, 2, 1, 4, 0],
            ],
        "line-offset": isOSMGraph
          ? 0
          : [
              "interpolate",
              ["exponential", 1.5],
              ["zoom"],
              5,
              ["match", ["get", "oneWay"], 0, 0.75, 1, 0, 0],
              12,
              ["match", ["get", "oneWay"], 0, 1.5, 1, 0, 0],
              18,
              ["match", ["get", "oneWay"], 0, 3, 1, 0, 0],
            ],
      },
    };
    const beforeId = getBeforeId(layerId, this.map);
    this.map.addLayer(routeGraphEdgesLayer, beforeId);
  }

  add({ date } = { date: "" }) {
    const versionDate = date && isToday(date) ? "" : date;
    this.addSource({ date: versionDate });
    this.edgeHighlightLayer.addLayer();
    this.verticesHighlighLayer.addLayer();
    this.vericesLayer.addLayer();
    this.addLayer();
    this.arrowLayer.addLayer();
  }

  removeSource() {
    if (this.map.getSource(GRAPH_SOURCE_ID)) this.map.removeSource(GRAPH_SOURCE_ID);
  }

  remove() {
    this.edgeHighlightLayer.remove();
    this.verticesHighlighLayer.remove();
    this.vericesLayer.remove();
    this.arrowLayer.remove();
    if (this.checkLayer()) this.map.removeLayer(this.layerId);

    this.removeSource();
  }

  addSource({ date }) {
    const url = RouterAPI.tiles.graph({
      clip: true,
      date,
    });
    this.map.addSource(GRAPH_SOURCE_ID, {
      type: "vector",
      tiles: [url],
    });
  }

  updateDate({ date }) {
    this.remove();
    this.add({ date });
  }
}
