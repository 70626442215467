import React from "react";
import SectorAnalysisCardIcon from "./sector-analysis-card.icon";
import * as Types from "./sector-analysis-card.types";
import * as utils from "./sector-analysis-card.utils";
import * as consts from "./sector-analysis-card.consts";
import * as Cards from "./cards";
import "./index.scss";
import { TagSectorAnalysis } from "./index.styled";
import { Tag } from "components";

export const SectorAnalysisCard = React.memo((props: Types.SectorAnalysisCardProps) => {
  let card: JSX.Element | null = null;

  switch (props.type) {
    case "route":
      card = <Cards.Route routes={props.routes} />;
      break;
    case "speed":
      card = <Cards.Speed speed={props.speed} freeFlowSpeed={props.freeFlowSpeed} />;
      break;
    case "time":
      card = <Cards.Time time={props.time} freeFlowTime={props.freeFlowTime} />;
      break;
    case "utilization":
      card = <Cards.Utilization utilization={props.utilization} freeFlowUtilization={props.freeFlowUtilization} />;
      break;
    case "volume":
      card = <Cards.Volume volume={props.volume} freeFlowVolume={props.freeFlowVolume} />;
      break;
    case "occ":
      card = <Cards.OCC occ={props.occ} freeFlowOcc={props.freeFlowOcc} />;
      break;
    case "density":
      card = <Cards.Density density={props.density} freeFlowDensity={props.freeFlowDensity} />;
      break;
  }

  return (
    <div className={consts.baseClass}>
      <div className={`${consts.baseClass}-header`}>
        <SectorAnalysisCardIcon type={props.type} />
        <span className={`${consts.baseClass}-title`}>
          {utils.getTitle(props.type)}
          {props.type === "volume" || props.type === "density" ? (
            <TagSectorAnalysis>
              <Tag title="Приведённая к легковым ТС">легк.</Tag>
            </TagSectorAnalysis>
          ) : null}
        </span>
      </div>
      {card}
    </div>
  );
});
