import React from "react";
import { graphUpdateIsActiveAC } from "old-store/view/action-creators";
import { useDispatch, useSelector } from "react-redux";
import { GState } from "documentations";
import { VisibilityState } from "utils/hooks";

export const useCtrlGraphVisibility = (): VisibilityState => {
  const dispatch = useDispatch();
  const isVisible = useSelector((state: GState) => state.view.graph);

  const toggle = React.useCallback(() => dispatch(graphUpdateIsActiveAC(!isVisible)), [dispatch, isVisible]);
  const show = React.useCallback(() => dispatch(graphUpdateIsActiveAC(true)), [dispatch]);
  const hide = React.useCallback(() => dispatch(graphUpdateIsActiveAC(false)), [dispatch]);

  return { isVisible, toggle, show, hide };
};
