type keyGenerator<T> = (item: T) => string;
/** works with primitives only (for now) */
export const distinctItems = <T>(array: T[], byProp?: keyof T | keyGenerator<T>): T[] => {
  if (byProp) {
    const map = new Map<any, T>();

    array.forEach((item) => {
      if (typeof item !== "object") {
        throw new TypeError(
          `Only objects are allowed in array with "byProp" in "distinctItems" function property received "${typeof item}" instead`
        );
      }
      const key = typeof byProp === "function" ? byProp(item) : item?.[byProp];
      map.set(key, item);
    });

    return Array.from(map.values());
  } else {
    const map = new Map<any, T>();

    array.forEach((item) => {
      const key = typeof item === "object" ? JSON.stringify(item) : item;
      if (!map.has(key)) {
        map.set(key, item);
      }
    });

    return Array.from(map.values());
  }
};
