import React, { useCallback, useMemo, useState } from "react";
import format from "date-fns/format";
import ru from "date-fns/locale/ru";
import addMonths from "date-fns/addMonths";
import { ReactComponent as ArrowLeft24Icon } from "./arrow-left-24.svg";
import { ReactComponent as ArrowRight24Icon } from "./arrow-right-24.svg";
import * as Markup from "./date-picker.styles";

interface Props {
  date: Date;
  onChange: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
}

const monthsArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const DatePickerMonthsComponent = (props: Props) => {
  const { date, onChange, minDate, maxDate } = props;
  const [year, setYear] = useState(+format(date, "yyyy"));

  const [min, max] = useMemo(
    () => [
      minDate ? new Date(minDate.setDate(1)).setHours(0, 0, 0, 0) : undefined,
      maxDate ? new Date(addMonths(maxDate, 1).setDate(1)).setHours(0, 0, 0, 0) : undefined,
    ],
    [minDate, maxDate]
  );

  const [isPrevDisabled, isNextDisabled] = useMemo(
    () => [minDate ? minDate.getFullYear() >= year : false, maxDate ? maxDate.getFullYear() <= year : false],
    [minDate, maxDate, year]
  );

  const handlePrevMonthClick = useCallback(() => {
    setYear((value) => value - 1);
  }, []);

  const handleNextMonthClick = useCallback(() => {
    setYear((value) => value + 1);
  }, []);

  return (
    <>
      <Markup.Header>
        <Markup.Prev onClick={handlePrevMonthClick} disabled={isPrevDisabled}>
          <ArrowLeft24Icon />
        </Markup.Prev>
        <Markup.Next onClick={handleNextMonthClick} disabled={isNextDisabled}>
          <ArrowRight24Icon />
        </Markup.Next>
        <Markup.Title>{year}</Markup.Title>
      </Markup.Header>
      <Markup.MonthSelection>
        {monthsArray.map((month) => {
          const value = new Date(`${year}-${String(month).padStart(2, "0")}-01T00:00:00`);
          const isSelected = date.getMonth() === month - 1 && +format(date, "yyyy") === year;
          const isDisabled = Boolean((min && value.getTime() < min) || (max && value.getTime() >= max));
          return (
            <Markup.Month key={month} aria-selected={isSelected} disabled={isDisabled} onClick={() => onChange(value)}>
              {format(value, "LLLL", { locale: ru })}
            </Markup.Month>
          );
        })}
      </Markup.MonthSelection>
    </>
  );
};

export const DatePickerMonths = React.memo(DatePickerMonthsComponent);
