import React, { memo } from "react";
import { useValues, useCallbacks } from "./average-speed-checkbox.hooks";
import { TemplateCheckbox } from "../template-checkbox";
import { RouteSpeedIcon } from "assets";
import "../template-checkbox/template-checkbox.scss";

const routeSpeedIcon = <RouteSpeedIcon />;

export const AverageSpeedCheckbox = memo(() => {
  const { isShowAverageSpeed } = useValues();
  const { handleIsShowAverageSpeedChange } = useCallbacks();

  return (
    <TemplateCheckbox
      icon={routeSpeedIcon}
      id={"average-speed-checkbox"}
      title={""}
      label={"Средняя скорость на участке (км/ч)"}
      checked={isShowAverageSpeed}
      onChange={handleIsShowAverageSpeedChange}
    />
  );
});
