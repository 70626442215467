import React, { memo } from "react";
import { NavigationGroupeLayers } from "components";
import { usePedestrianGraphVisibility } from "./use-pedestrian-graph-visibility";
import * as consts from "./pedestrian-graph.consts";

export const PedestrianGraph = memo(() => {
  const { isDisabled, isVisible, toggle } = usePedestrianGraphVisibility();

  const handleClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      toggle();
    },
    [toggle]
  );

  return (
    <div className="ctrl-graph">
      <NavigationGroupeLayers.Item
        iconNormalUrl={consts.buttonProps.iconNormalUrl}
        iconActiveUrl={consts.buttonProps.iconActiveUrl}
        title={consts.buttonProps.title}
        isDisabled={isDisabled}
        isActive={isVisible}
        onClick={handleClick}
      />
    </div>
  );
});
