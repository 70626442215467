import styled from "styled-components";

export const Tag = styled.span`
  font-size: 11px;
  line-height: 16px;
  background-color: #b5bcc2;
  color: #ffffff;
  min-height: 15px;
  max-width: 36px;
  padding: 0 4px;
  border-radius: 4px;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
