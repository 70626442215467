import React from "react";
import * as utils from "../../sector-analysis-card.utils";
import * as Types from "../../sector-analysis-card.types";
import { Row } from "../row";

export const Speed = (props: Omit<Types.SpeedCard, "type">) => {
  const { freeFlowSpeed, speed } = props;
  return (
    <>
      <Row title="Средняя" value={utils.formatSpeed(speed)} deviation={utils.deviation(speed, freeFlowSpeed)} />
      <Row title="Без пробок" value={utils.formatSpeed(freeFlowSpeed)} />
    </>
  );
};
