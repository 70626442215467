import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { GState } from "documentations";
import { loadAlternativeScoreParams, loadCityFlowParams } from "../store/slice";
import { FlowParamsType } from "../types";
import { historyTravelSelector } from "../store/selectors";

const getRound = (value?: string) => (value !== undefined ? Math.round(Number(value)) : "");
const getPercentValue = (value?: string) => (value !== undefined ? getRound(`${Number(value) * 100}`) : "");

const getValues = (params: FlowParamsType[], currentScores: Pick<FlowParamsType, "scoreMSG" | "score" | "time">[]) => {
  const times = currentScores.map((i) => i.time);
  params = params.filter((param) => times.includes(param.time));

  const averageSpeedArray = params.map((param) => ({
    value: getRound(param.averageSpeed),
    time: param.time,
  }));
  const freeFlowAverageSpeedArray = params.map((param) => ({
    value: getRound(param.freeFlowAverageSpeed),
    time: param.time,
  }));
  const averageDensityArray = params.map((param) => ({
    value: getRound(param.averageDensity),
    time: param.time,
  }));
  const averageOccArray = params.map((param) => ({
    value: getRound(param.averageOcc),
    time: param.time,
  }));
  const averageUtilizationArray = params.map((param) => ({
    value: getRound(param.averageUtilization),
    time: param.time,
  }));
  const averageVolumeArray = params.map((param) => ({
    value: getRound(param.averageVolume),
    time: param.time,
  }));
  const scoreArray = currentScores.map((param) => ({ value: getRound(param.score), time: param.time }));
  const alternativeScoreArray = params.map((param) => ({
    value: getPercentValue(param.alternativeScore),
    time: param.time,
  }));

  return {
    averageSpeedArray,
    freeFlowAverageSpeedArray,
    averageOccArray,
    averageUtilizationArray,
    averageVolumeArray,
    scoreArray,
    alternativeScoreArray,
    averageDensityArray,
  };
};

export const labelsWithValues: { label: string; value: keyof ReturnType<typeof getValues> }[] = [
  { label: "Средняя скорость, км/ч", value: "averageSpeedArray" },
  { label: "Плотность потока, ед/км", value: "averageDensityArray" },
  { label: "Загруженность, %", value: "averageUtilizationArray" },
  { label: "Интенсивность потока, ед/ч", value: "averageVolumeArray" },
  { label: "Занятость дороги, %", value: "averageOccArray" },
  { label: "Индекс дорожных заторов, %", value: "alternativeScoreArray" },
  { label: "Балл пробок", value: "scoreArray" },
];

const viewSelector = (state: GState) => state.view;
const roadDetectorSelector = (state: GState) => state.roadDetector;

export const useGetParams = () => {
  const dispatch = useDispatch<any>();

  const { detectorRoadFlowParams, trafficFlowParams, detectorScores } = useSelector(historyTravelSelector);
  const { selectedTime } = useSelector(viewSelector);
  const { isRoadDetectorMode } = useSelector(roadDetectorSelector);
  const [isLoad, setIsLoad] = useState(false);

  useEffect(() => {
    if (isRoadDetectorMode) {
      setIsLoad(true);
      dispatch(loadCityFlowParams({ selectedTime })).then(() => {
        dispatch(loadAlternativeScoreParams({ selectedTime })).finally(() => {
          setIsLoad(false);
        });
      });
    }
  }, [isRoadDetectorMode, selectedTime]);

  const currentParams = useMemo(
    () => (isRoadDetectorMode ? detectorRoadFlowParams : trafficFlowParams),
    [isRoadDetectorMode, detectorRoadFlowParams, trafficFlowParams]
  );

  const currentScores = useMemo(() => (isRoadDetectorMode ? detectorScores : []), [isRoadDetectorMode, detectorScores]);

  const isFutureIndexes: number[] = [];

  currentParams?.forEach((item, index) => {
    moment().diff(item.time) < 0 && isFutureIndexes.push(index);
  });

  const dynamicsMessages = {
    alternativeScoreMSG: currentParams[currentParams.length - 1]?.alternativeScoreMSG,
    scoreMSG: currentParams[currentParams.length - 1]?.scoreMSG,
  };

  const values = useMemo(() => getValues(currentParams, currentScores), [currentParams, currentScores]);

  return { values, isFutureIndexes, isLoad, dynamicsMessages };
};
