import React from "react";
import { useOnClickOutside, useVisibility } from "utils/hooks";
import * as Types from "./version-picker.types";
import * as Components from "./components";
import * as consts from "./version-picker.consts";
import "./version-picker.scss";

export const VersionPicker = (props: Types.Props) => {
  const { isVisible, toggle, hide } = useVisibility();
  const ref = React.useRef<HTMLDivElement>(null);

  const handleClickOutside = React.useCallback(() => {
    hide();
  }, [hide]);

  const onChange = React.useCallback(
    (value: string | null) => {
      props.onChange?.(value);
      hide();
    },
    [props.onChange, hide]
  );

  useOnClickOutside(ref, handleClickOutside);

  return (
    <div ref={ref} className={consts.versionPickerClass}>
      <Components.Input value={props.currentDate} onClick={toggle} />
      {isVisible && (
        <Components.Dropdown
          onChange={onChange}
          historyVersions={props.historyVersions}
          selectedDates={props.selectedDates}
        />
      )}
    </div>
  );
};
