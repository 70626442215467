import { createStore } from "effector";
import { events } from "./graph-compare.events";
import * as reducers from "./graph-compare.reducers";
import { State } from "./graph-compare.types";
import { v1 as uuidv1 } from "uuid";
import moment from "moment";
import { effects } from "./graph-compare.effects";

const initialState: State = {
  selectedGraphTypes: {
    pedestrian: false,
    road: false,
  },
  isVisible: false,
  graphVersions: [{ id: String(uuidv1()), date: moment().toISOString(), isLoading: true, hasRequested: false }],
  historyVersions: [],
  selectedHistoryIndex: 0,
};

export const store = createStore(initialState);

store.on(events.handleIsVisibleChange, reducers.setIsVisible);
store.on(events.deleteVersionById, reducers.deleteVersion);
store.on(events.setDateOfVersion, reducers.setDateOfVersion);
store.on(events.createVersion, reducers.createVersion);
store.on(events.setSelectedGraphTypes, reducers.setSelectedGraphTypes);
store.on(events.setIsRequested, reducers.setIsRequested);
store.on(events.setSelectedHistoryIndex, reducers.setSelectedIndex);
store.on(events.getInit, () => initialState);

store.on(effects.fetchGraphByDate.doneData, reducers.addVersion);
store.on(effects.fetchGraphVersions.doneData, reducers.addHistoryVersion);

store.watch((state) =>
  state.graphVersions.forEach(async (item) => {
    if (item.isLoading && state.isVisible && !item.hasRequested) {
      events.setIsRequested({ id: item.id, hasRequested: true });
      await effects.fetchGraphByDate({ date: item.date, graphType: state.selectedGraphTypes });
    }
  })
);
