import { RefObject, useEffect, useRef } from "react";
type Event = MouseEvent | TouchEvent;

export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: Event) => void,
  clickSpeed = 200
) => {
  const mouseDownTime = useRef<number>(0);
  useEffect(() => {
    const listener = (event: Event) => {
      const el = ref?.current;
      if (!el || el.contains((event?.target as Node) || null)) {
        return;
      }
      if (Date.now() - mouseDownTime.current < clickSpeed) {
        handler(event);
      }
    };

    const mouseDownHandler = () => {
      mouseDownTime.current = Date.now();
    };

    document.addEventListener("mousedown", mouseDownHandler);
    document.addEventListener("mouseup", listener);
    document.addEventListener("touchstart", mouseDownHandler);
    document.addEventListener("touchend", listener);

    return () => {
      document.removeEventListener("mousedown", mouseDownHandler);
      document.removeEventListener("touchstart", mouseDownHandler);
      document.removeEventListener("mouseup", listener);
      document.removeEventListener("touchend", listener);
    };
  }, [ref, handler, clickSpeed]);
};
