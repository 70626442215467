import { shared } from "shared";
import { DayOfWeek } from "api/router/model/models";
import { AnalysisCell, AnalysisDiagramData, AnalysisGraph, AnalysisTotal } from "api/router/model/analysisResponse";
import { TrafficLightProperties } from "features/ctrl-route/types";

export enum AnalysisType {
  current = "current",
  compare = "compare",
}

export type ChangeDateProps = {
  dateFrom: string;
  dateTo: string;
};

export type AnalysisData = {
  /** Период с */
  dateFrom: string;
  /** Период по */
  dateTo: string;
  /** Внешние системы */
  es: Array<number>;
  type: AnalysisType;
  /** Флаг загрузки */
  isLoading: boolean;
  days: Array<DayOfWeek>;
  diagram: AnalysisDiagramData | null;
  graph: AnalysisGraph | null;
  total: AnalysisTotal | null;
};

export interface AnalysisGraphRange {
  maxSpeed?: number | null;
  minSpeed?: number | null;
  maxVolume?: number | null;
  minVolume?: number | null;
  maxDensity?: number | null;
  minDensity?: number | null;
  maxTime?: number | null;
  minTime?: number | null;
  maxUnitsCount?: number | null;
  minUnitsCount?: number | null;
}

export type LimitsKeys = keyof Omit<typeof shared.unitsCountDiagram.unitsCountColor, -1>;
export type UnitsCountDiagramParamsLimits = { [key in LimitsKeys]: number };

export type UnitsCountDiagramParams = {
  max: number;
  colorPrice: number;
  limits: UnitsCountDiagramParamsLimits;
};

export type SectorAnalysisState = {
  /** Активен контрол или нет */
  isActive: boolean;
  /** Заблокирован контpол или нет */
  isDisabled: boolean;
  /** Flag is active headmap */
  isActiveHeatmap: boolean;
  /** Текущий анализ */
  current: AnalysisData;
  /** Анализ для сравнения */
  compare: AnalysisData;
  /** Показывать события или нет */
  isShowEvents: boolean;
  /** Показывать анализ для сравнения или нет */
  isShowCompare: boolean;
  /** Ключи дат сортированные в порядке возрастания */
  dateKeys: Array<string>;
  /** Период для aнализа */
  periodM: number;
  /** Максимальные и минимальные значения для обоих графиков */
  graphRange: AnalysisGraphRange | null;
  /** Флаг показа подсказки для зума */
  isZoomTooltip: boolean;
  /** Флаг показа меню настройки цветовой схемы */
  isAppearanceMenu: boolean;
  /** Флаг показа легенды диаграммы */
  isShowLegend: boolean;
  /** Признак работоспособности элементов фильтрации на диаграмме */
  isFiltersDisabled: boolean;
  /** Признак работоспособности диаграммы */
  isDiagramDisabled: boolean;
  /** Признак отображения графика средней скорости */
  isShowAverageSpeed: boolean;
  /** Признак отображения графика среднего времени пути */
  isShowAverageTime: boolean;
  /** Признак отображения графика средней интенсивности движения */
  isShowAverageVolume: boolean;
  /** Признак отображения графика средней плотности потока */
  isShowAverageDensity: boolean;
  /** Признак отображения графика балла пробок на участке */
  isShowTrafficScore: boolean;
  /** Признак отображения графика балла пробок по городу */
  isShowGlobalTrafficScore: boolean;
  /** Признак отображения графика ИДЗ на участке */
  isShowTrafficIndex: boolean;
  /** Признак отображения графика ИДЗ по городу */
  isShowGlobalTrafficIndex: boolean;
  /** Признак отображения диаграмы по кол-ву ТС */
  isUnitsCountDiagram: boolean;
  /** Значение максимума ТС и цены деления палитры */
  unitsCountDiagramParams: UnitsCountDiagramParams | null;
  /** Отображать ли график кол-ва ТС */
  isUnitsCountGraph: boolean;
  /** Отображать ли диаграму в 3D */
  isVolumetric: boolean;
  /** Отображать ли 3D в полноэкранном режиме */
  isVolumetricFullscreen: boolean;
  /** Прозрачность сегментов ДАУ */
  segmentsOpacity: number;
  /** [Минимальное, Максимальное] кол-во ТС для фильтрации 3D ДАУ */
  minMaxUnitsCount3DFilter: [number, number];
};

export type AnalysisDiagramRectParams = {
  x: number;
  y: number;
  w: number;
  h: number;
  color: string;
  data: AnalysisCell | undefined;
  date: string;
  freeFlowSpeed: number;
};

export type AnalysisDiagramRects = {
  [x: string]: AnalysisDiagramRectParams;
};

export type AnalysisDiagramCallbackParams = {
  column: number;
  row?: number;
  rectangle?: AnalysisDiagramRectParams;
  type: string;
  rectangleX?: number;
  rectangleY?: number;
};

export type AnalysisDiagramOptions = {
  data: AnalysisDiagramData | null;
  dateKeys: Array<string>;
  type: AnalysisType;
  period: number;
  graphData: AnalysisGraph | null;
  graphRange: AnalysisGraphRange | null;
  compareWith: AnalysisData;
  total: AnalysisTotal | null;
  appearance: DiagramAppearance;
  trafficLights?: TrafficLightProperties[];
  isUnitsCountDiagram: boolean;
  visibility: {
    isShowAverageSpeed: SectorAnalysisState["isShowAverageSpeed"];
    isShowAverageVolume: SectorAnalysisState["isShowAverageVolume"];
    isShowAverageDensity: SectorAnalysisState["isShowAverageDensity"];
    isShowAverageTime: SectorAnalysisState["isShowAverageTime"];
    isShowTrafficScore: SectorAnalysisState["isShowTrafficScore"];
    isShowGlobalTrafficScore: SectorAnalysisState["isShowGlobalTrafficScore"];
    isShowTrafficIndex: SectorAnalysisState["isShowTrafficIndex"];
    isShowGlobalTrafficIndex: SectorAnalysisState["isShowGlobalTrafficIndex"];
    isUnitsCountDiagram: SectorAnalysisState["isUnitsCountDiagram"];
    isUnitsCountGraph: SectorAnalysisState["isUnitsCountGraph"];
  };
};

export type DiagramDrawLineOptions = { values: Array<number | null>; color: string; min: number; max: number };

export enum DiagramEvent {
  ZOOM = "ZOOM",
  SCROLL = "SCROLL",
  MOUSE_MOVE = "MOUSE_MOVE",
  DBL_CLICK = "DBL_CLICK",
  LEAVE_DIAGRAM = "LEAVE_DIAGRAM",
  MOVE_GRAPH = "MOVE_GRAPH",
  LEAVE_GRAPH = "LEAVE_GRAPH",
  LEAVE = "LEAVE",
  MOUSE_OUT = "MOUSE_OUT",
  UPDATE_ROW = "UPDATE_ROW",
  HIDE_DIAGRAM_POPUPS = "HIDE_DIAGRAM_POPUPS",
  ZOOMING_X = "ZOOMING_X",
  SYNC_CROSSHAIR = "SYNC_CROSSHAIR",
  SYNC_GRAPH_CROSSHAIR = "SYNC_GRAPH_CROSSHAIR",
  GRAPH_CLICK = "GRAPH_CLICK",
  SYNC_DIAGRAM_SELECTION = "SYNC_DIAGRAM_SELECTION",
  SET_IS_FILTER_DISABLED = "SET_IS_FILTER_DISABLED",
  SYNC_APPEARANCE = "SYNC_APPEARANCE",
}

export enum DiagramMouseRegions {
  outside = "outside",
  graph = "graph",
  diagram = "diagram",
}
