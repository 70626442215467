/* eslint-disable @typescript-eslint/no-non-null-assertion */
import mapboxgl, { LngLatLike, Popup } from "mapbox-gl";
import * as turf from "@turf/turf";
import * as THREE from "three";
import { MiddlewareAPI } from "redux";
import moment from "moment";
import { GState } from "documentations";
import { RouteCosting } from "api/router/model/router";
import RouteLayer from "map-helpers/layers/route-layer";
import RoutePointLayer from "map-helpers/layers/route-point-layer";
import getImage from "map-helpers/assets/route-images";
import RouteStrokeLayer from "map-helpers/layers/route-stroke";
import RouteAlternativeLayer from "map-helpers/layers/route-alternative-layer";
import RouteAlternativeStrokeLayer from "map-helpers/layers/route-alternative-stroke-layer";
import { decodeShape, shapeToGeoJson } from "utils/shape-to-geojson";
import RouteIntermediatePointLayer from "map-helpers/layers/route-intermediate-point-layer";
import { shared } from "shared";
import { RouterAPI } from "api/router";
import { geocode } from "api/geocoder/geocode";
import getBeforeId, {
  ROUTE_BUS_FOOT,
  ROUTE_BUS_TRANSIT,
  ROUTE_INTERMEDIATE_POINT,
  ROUTE_METRO,
  ROUTE_PATH,
  ROUTE_PEDESTRIAN,
  ROUTE_SIMULATED_DTP_LAYER,
  ROUTE_TLS,
  ROUTE_VOLUMETRIC_DIAGRAM,
} from "map-helpers/order-layers";
import { UnitsCountDiagramParams } from "../../sector-analysis/types";
import * as Store from "../store";
import * as Layers from "../map-layers";
import { getBusRouteGeometry } from "../utils/get-bus-route-geometry";
import { RouteProperties, RouteState } from "../types";
import { RouteSimulatedDtpPopup } from "./route-simulated-dtp-popup";
import { RouteTransportCountPopup } from "./route-transport-count-popup";
import "./route-length-error-popup.scss";

class RouteMapController {
  private routeLayer: RouteLayer;
  private routeStrokeLayer: RouteStrokeLayer;
  private routeAltLayer: RouteAlternativeLayer;
  private routeAltStrokeLayer: RouteAlternativeStrokeLayer;
  private routeYandexLayer: RouteAlternativeLayer;
  private routeYandexStrokeLayer: RouteAlternativeStrokeLayer;
  private pointLayers: Array<RoutePointLayer | RouteIntermediatePointLayer>;
  private routeTimeVariant: RouteLayer;
  private routeTimeVariantStroke: RouteStrokeLayer;
  private listenerIsActive = false;
  private clickTimeout: NodeJS.Timeout | undefined;
  private mainPointPattern = /route-point-\w/i;
  private interPointPattern = /route-intermediate-point-\w/i;
  private draggableLayer: string | null = null;
  private draggableKey: string | null = null;
  private intermediatePointLayer: RouteIntermediatePointLayer | null = null;
  private routeTlsLayer: Layers.RouteTlsLayer;
  private routeBusLayer: Layers.RouteBusLayer;
  private routeBusPedestrianLayer: Layers.RouteBusLayerPedestrian;
  private routeMetroLayer: Layers.RouteMetroLayer;
  private routePedestrianLayer: Layers.RoutePedestrianLayer;
  private routeTransportCount: Layers.RouteTransportCount;
  private routeFlatDiagram: Layers.RouteDiagramFlatLayer;
  private routeLengthErrorPopup: Popup;
  private routeTransportCountPopup: RouteTransportCountPopup;
  private routeSimulatedDtpPopup: RouteSimulatedDtpPopup;
  private hovered: THREE.Intersection | null = null;
  private isRouteLengthError = false;
  private simulatedDtpLayer: Layers.RouteSimulatedDtpLayer;
  private isSimulatedDtp = false;
  private simulatedDtp: [number, number] | null = null;
  private isUnitsCountVisibility = false;
  private unitsCountDiagramParams: UnitsCountDiagramParams | null = null;

  private get isAddDtpMode() {
    return this.isSimulatedDtp && !this.simulatedDtp;
  }

  constructor(private map: mapboxgl.Map, private store: MiddlewareAPI<any, GState>) {
    this.routeLayer = new RouteLayer(this.map);
    this.routeStrokeLayer = new RouteStrokeLayer(this.map);
    this.routeAltLayer = new RouteAlternativeLayer(this.map);
    this.routeAltStrokeLayer = new RouteAlternativeStrokeLayer(this.map);
    this.routeYandexLayer = new RouteAlternativeLayer(this.map);
    this.routeYandexStrokeLayer = new RouteAlternativeStrokeLayer(this.map);
    this.routeTimeVariant = new RouteLayer(this.map);
    this.routeTimeVariantStroke = new RouteStrokeLayer(this.map);
    this.routeFlatDiagram = new Layers.RouteDiagramFlatLayer(this.map);
    this.intermediatePointLayer = new RouteIntermediatePointLayer(map, ROUTE_INTERMEDIATE_POINT);
    this.routeTlsLayer = new Layers.RouteTlsLayer(this.map, ROUTE_TLS, "route-tls-source");
    this.routeBusLayer = new Layers.RouteBusLayer(this.map, ROUTE_BUS_TRANSIT, "route-bus-source-transit");
    this.routeMetroLayer = new Layers.RouteMetroLayer(this.map, ROUTE_METRO, ROUTE_METRO);
    this.simulatedDtpLayer = new Layers.RouteSimulatedDtpLayer(this.map);

    this.routeBusPedestrianLayer = new Layers.RouteBusLayerPedestrian(
      this.map,
      ROUTE_BUS_FOOT,
      "route-bus-source-foot"
    );
    this.routePedestrianLayer = new Layers.RoutePedestrianLayer(this.map, ROUTE_PEDESTRIAN, "route-pedestrian-source");
    this.routeTransportCount = new Layers.RouteTransportCount(this.map);
    this.map.addLayer(this.routeTransportCount, getBeforeId(ROUTE_VOLUMETRIC_DIAGRAM, this.map));

    this.pointLayers = [];
    this.routeLengthErrorPopup = new Popup({
      closeButton: false,
      closeOnClick: false,
      className: "route-length-error-popup",
    });

    this.routeTransportCountPopup = new RouteTransportCountPopup(this.map);

    this.routeSimulatedDtpPopup = new RouteSimulatedDtpPopup(this.map);
    this.map.on("style.load", this.handleStyleLoad);
  }

  public addListeners() {
    if (!this.listenerIsActive) {
      this.map.on("mousedown", this.handleMouseDownDragPoint);
      this.map.on("dblclick", this.handleMouseDblClick);
      this.map.on("click", this.handleMouseClickCreate);
      this.map.on("click", this.handleMouseClickAltLayer);
      this.map.on("mousemove", this.handleMouseMoveIntermediatePoint);
      this.map.on("mousedown", this.handleMouseDownIntermediatePoint);
      this.map.on("mousemove", this.handleMouseMoveCursor);
      this.map.on("pitch", this.handlePitchChange);
      this.listenerIsActive = true;
    }
  }

  public removeListeners() {
    if (this.listenerIsActive) {
      this.map.off("mousedown", this.handleMouseDownDragPoint);
      this.map.off("dblclick", this.handleMouseDblClick);
      this.map.off("click", this.handleMouseClickCreate);
      this.map.off("click", this.handleMouseClickAltLayer);
      this.map.off("mousemove", this.handleMouseMoveIntermediatePoint);
      this.map.off("mousedown", this.handleMouseDownIntermediatePoint);
      this.map.off("mousemove", this.handleMouseMoveCursor);
      this.map.off("pitch", this.handlePitchChange);
      this.listenerIsActive = false;
    }
  }

  private handleStyleLoad = () => {
    this.simulatedDtpLayer?.destroy();
    this.simulatedDtpLayer = new Layers.RouteSimulatedDtpLayer(this.map);
    this.simulatedDtpLayer = new Layers.RouteSimulatedDtpLayer(this.map);
    this.routeTransportCount = new Layers.RouteTransportCount(this.map);
    this.map.addLayer(this.routeTransportCount, getBeforeId(ROUTE_VOLUMETRIC_DIAGRAM, this.map));
    this.updateRoute();
    this.updatePoints();
    this.handlePitchChange();
  };

  private handlePitchChange = () => {
    const pitch = this.map.getPitch();

    if (pitch < 20) {
      this.routeTransportCount.visibility = false;
      this.routeTransportCountPopup.remove();
      this.routeFlatDiagram.setVisibility(true);
      return;
    }

    if (this.routeTransportCount.visibility) return;
    this.routeFlatDiagram.setVisibility(false);
    this.routeTransportCount.visibility = true;
  };

  private handleMouseMoveCursor = (e: mapboxgl.MapMouseEvent) => {
    const {
      router: { costing },
    } = this.store.getState();
    const point = this.getPointId();
    if (point && costing !== RouteCosting.Template) this.map.getCanvas().style.cursor = "crosshair";
    if (!this.routeTransportCount.visibility) return;

    const hovered = this.routeTransportCount.raycast(e.point)?.[0] ?? null;

    if (!hovered) {
      if (this.hovered) {
        // @ts-ignore
        this.hovered.object.material.opacity = 0.5;
      }
      this.hovered = null;
      this.routeTransportCountPopup.remove();
      return this.map.triggerRepaint();
    }

    if (this.hovered) {
      // @ts-ignore
      this.hovered.object.material.opacity = 0.5;
    }
    // @ts-ignore
    hovered.object.material.opacity = 0.7;
    this.hovered = hovered;
    const properties = this.routeTransportCount.getProperties(this.hovered.object.uuid);

    this.routeTransportCountPopup.setData(properties).setLngLat(e.lngLat).show();
    this.map.triggerRepaint();
  };

  private handleMouseMoveDragInter = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    if (this.draggableKey) {
      const location = new mapboxgl.LngLat(e.lngLat.lng, e.lngLat.lat);
      this.map.getCanvas().style.cursor = "grabbing";
      if (location && this.intermediatePointLayer) this.intermediatePointLayer.update(location.toArray());
    }
  };

  private handleMouseUpInter = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    const location = new mapboxgl.LngLat(e.lngLat.lng, e.lngLat.lat);
    // @ts-ignore
    clearTimeout(this.clickTimeout);

    if (this.draggableKey) this.store.dispatch(Store.actions.updatePoint({ location, key: this.draggableKey }));

    this.map.on("mousemove", this.handleMouseMoveIntermediatePoint);
    this.map.off("mousemove", this.handleMouseMoveDragInter);
  };

  private handleMouseDownIntermediatePoint = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    if (this.isAddDtpMode) return;
    const features = this.map.queryRenderedFeatures(e.point);
    const feature = features.shift();

    if (feature?.layer.id === ROUTE_INTERMEDIATE_POINT) {
      e.preventDefault();
      const location = new mapboxgl.LngLat(e.lngLat.lng, e.lngLat.lat);

      this.clickTimeout = setTimeout(() => {
        this.map.getCanvas().style.cursor = "grab";
        this.draggableKey = String(Date.now().valueOf());
        this.store.dispatch(Store.actions.addIntermediatePoint({ location, key: this.draggableKey }));
        this.map.off("mousemove", this.handleMouseMoveIntermediatePoint);
        this.map.on("mousemove", this.handleMouseMoveDragInter);
        this.map.once("mouseup", this.handleMouseUpInter);
      }, 200);
    }
  };

  private handleMouseMoveIntermediatePoint = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    const dtpFeatures = this.getDtpFeatures(e.point);
    if (this.isAddDtpMode || dtpFeatures.length) {
      return this.intermediatePointLayer?.update([]);
    }
    const {
      router: { routeVariants, activeIndexRoute },
    } = this.store.getState();

    const width = 20;
    const height = 20;

    const features = this.map.queryRenderedFeatures([
      [e.point.x - width / 2, e.point.y - height / 2],
      [e.point.x + width / 2, e.point.y + height / 2],
    ]);

    if (features.length) {
      const routePoint = features.find((el) => el.layer.id.match(this.mainPointPattern));
      const intermediatePoint = features.find((el) => el.layer.id.match(this.interPointPattern));
      const routePath = features.find((el) => el.layer.id === ROUTE_PATH);

      if (!routePoint && !intermediatePoint && routePath && routeVariants) {
        const location = new mapboxgl.LngLat(e.lngLat.lng, e.lngLat.lat);
        const pointsCoordiantes = decodeShape(routeVariants[activeIndexRoute].original.trip.legs[0].shape);
        const line = turf.lineString(pointsCoordiantes);
        const pt = turf.point(location.toArray());
        const snapped = turf.nearestPointOnLine(line, pt);
        const coordinates = snapped.geometry?.coordinates;
        if (coordinates && this.intermediatePointLayer) this.intermediatePointLayer.update(coordinates);
        if (this.isRouteLengthError && Array.isArray(coordinates)) {
          this.routeLengthErrorPopup.setLngLat(coordinates as LngLatLike);
        }
      } else this.intermediatePointLayer?.remove();
    }
  };

  private getDtpFeatures = (point: mapboxgl.Point) => {
    return this.map.queryRenderedFeatures(point, {
      layers: [ROUTE_SIMULATED_DTP_LAYER],
    });
  };

  private handleMouseDblClick = (ev: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    const dtpFeatures = this.getDtpFeatures(ev.point);
    if (dtpFeatures.length) {
      return this.store.dispatch(Store.routerSlice.actions.setIsSimulatedDtp(false));
    }

    const {
      router: { points, path, costing },
    } = this.store.getState();
    const point = (costing === RouteCosting.Template ? path : points).find((el) => el.coor);

    if (!point) return;

    // @ts-ignore
    clearTimeout(this.clickTimeout);

    const features = this.map.queryRenderedFeatures(ev.point);
    const feature = features.shift();

    if (!feature) return;

    const routePoint = costing === RouteCosting.Template ? null : feature.layer.id.match(this.mainPointPattern);
    const intermediatePoint = feature.layer.id.match(this.interPointPattern);

    if (!routePoint && !intermediatePoint) return;

    ev.preventDefault();
    const key = feature?.properties?.id!;
    if (key) this.store.dispatch(Store.routerSlice.actions.clearPoint(key));
    this.map.getCanvas().style.cursor = "default";
  };

  private handleMouseMoveDragPoint = (ev: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    if (this.draggableLayer && this.draggableKey) {
      const location = new mapboxgl.LngLat(ev.lngLat.lng, ev.lngLat.lat);
      this.map.getCanvas().style.cursor = "grabbing";

      const geojson = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: location.toArray(),
            },
            properties: {
              id: this.draggableKey,
            },
          },
        ],
      };

      const source = this.map.getSource(this.draggableLayer);
      // @ts-ignore
      if (source) source.setData(geojson);
    }
  };

  private handleMouseUpDragPoint = (ev: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    const location = new mapboxgl.LngLat(ev.lngLat.lng, ev.lngLat.lat);

    // @ts-ignore
    clearTimeout(this.clickTimeout);

    if (this.draggableKey) this.store.dispatch(Store.actions.updatePoint({ location, key: this.draggableKey }));

    this.draggableLayer = null;
    this.draggableKey = null;

    this.map.getCanvas().style.cursor = "default";
    this.map.off("mousemove", this.handleMouseMoveDragPoint);
  };

  private handleMouseDownDragPoint = (ev: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    const features = this.map.queryRenderedFeatures(ev.point);
    const feature = features.shift();
    // @ts-ignore
    clearTimeout(this.clickTimeout);

    if (!feature) return;

    const isMainPoint = feature.layer.id.match(this.mainPointPattern);
    const isInterPoint = feature.layer.id.match(this.interPointPattern);

    if (!isMainPoint && !isInterPoint) return;

    ev.preventDefault();

    this.clickTimeout = setTimeout(() => {
      this.draggableLayer = feature.layer.id;
      this.draggableKey = feature.properties?.id!;
      this.routeLengthErrorPopup.isOpen() && this.routeLengthErrorPopup.remove();
      this.map.getCanvas().style.cursor = "grab";
      this.map.on("mousemove", this.handleMouseMoveDragPoint);
      this.map.once("mouseup", this.handleMouseUpDragPoint);
    }, 200);
  };

  //#region create point

  private getAddress = async (coordinates: [number, number]) => {
    const [lng, lat] = coordinates;
    try {
      const addressData = await geocode.address({ lat, lng });
      return addressData?.address || "";
    } catch {
      return "";
    }
  };

  private handleMouseClickCreate = async (ev: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    if (this.isAddDtpMode) {
      const coordinates = ev.lngLat.toArray() as [number, number];
      const date = moment().toDate();
      this.store.dispatch(Store.routerSlice.actions.setSimulatedDtpDate(date));
      this.store.dispatch(Store.routerSlice.actions.setSimulatedDtp(coordinates));
      this.routeSimulatedDtpPopup.setDate(date);
      this.routeSimulatedDtpPopup.setIsLoading(true);
      const address = await this.getAddress(coordinates);
      this.routeSimulatedDtpPopup.setIsLoading(false);
      return this.routeSimulatedDtpPopup.setAddress(address);
    }

    const {
      router: { isActive, costing },
    } = this.store.getState();

    const pointId = this.getPointId();

    this.routeLengthErrorPopup.isOpen() && this.routeLengthErrorPopup.remove();

    if (isActive && pointId && costing !== RouteCosting.Template) {
      const location = new mapboxgl.LngLat(ev.lngLat.lng, ev.lngLat.lat);
      this.store.dispatch(Store.actions.mapClickNewPoint(location));
    }
  };

  //#endregion

  private handleMouseClickAltLayer = (ev: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    if (this.isAddDtpMode) return;

    const {
      router: { routeVariants, costing },
    } = this.store.getState();

    if (!routeVariants || routeVariants.length < 2 || costing === RouteCosting.Template) return;

    const features = this.map.queryRenderedFeatures(ev.point);
    this.store.dispatch(Store.actions.mapClick(features));
  };

  private getPointId() {
    const {
      router: { points, path, costing },
    } = this.store.getState();
    return (costing === RouteCosting.Template ? path : points).find((point) => !point.coor && point.isMain);
  }

  private setDataToVolumetricDiagram = () => {
    const state = this.store.getState();
    const path = state.router.routeVariants?.[state.router.activeIndexRoute].paintedPath?.features;

    const data = path
      ?.map((feature) => {
        const _feature = { ...feature };
        const start = _feature.geometry.coordinates[0] ?? [];
        const end = _feature.geometry.coordinates[_feature.geometry.coordinates.length - 1] ?? [];

        if (!start.length || !end.length) return null;

        const _start = new mapboxgl.LngLat(start[0], start[1]);
        const _end = new mapboxgl.LngLat(end[0], end[1]);
        const bearing = turf.bearing(start, end);
        const coords = [start, end];
        const line = turf.lineString(coords);
        const length = turf.length(line) * 1000;
        const rad = bearing * (Math.PI / 180);
        return {
          id: Symbol("1"),
          start: _start,
          end: _end,
          bearing: rad,
          length,
          color: _feature.properties.trafficColor,
          unitsCount: _feature.properties.unitsCount,
          volume: _feature.properties.volume,
        };
      })
      .filter((item) => !!item) as Layers.Types.RoutePiece[];

    this.routeTransportCount.setData(data);
  };

  public setIsRouteDiagram = (isRouteDiagram: boolean) => {
    if (!this.map.getLayer(ROUTE_VOLUMETRIC_DIAGRAM)) {
      // this.map.addLayer(this.routeVolumetricDiagram, getBeforeId(ROUTE_VOLUMETRIC_DIAGRAM, this.map));
    }

    if (!isRouteDiagram) {
      this.routeFlatDiagram.setVisibility(false);
      this.routeTransportCount.setData([]);
      this.routeTransportCount.visibility = false;
      this.routeTransportCountPopup.remove();
      return;
    }

    const pitch = this.map.getPitch();

    this.setDataToVolumetricDiagram();

    if (pitch < 20) {
      // @note setVisibility true to flat layer
      this.routeFlatDiagram.setVisibility(true);
      this.routeTransportCount.visibility = false;
      this.routeTransportCountPopup.remove();
      return;
    }
  };

  /**
   * Камера центрируется на маршруте
   * @param routeVariants - варианты маршрутов
   */
  public boundMap() {
    const {
      router: { routeVariants, isActive: routeIsActive },
      sectorAnalysis: { isActive: sectorAnalysisIsActive },
    } = this.store.getState();

    if (!routeIsActive) return;

    let max_min_lon = 1000;
    let max_max_lon = 0;
    let max_max_lat = 0;
    let max_min_lat = 1000;

    if (routeVariants) {
      /** Находим максиальные границы для всeх маршрутов */
      routeVariants.forEach((variant) => {
        const { min_lon, max_lon, max_lat, min_lat } = variant.original.trip.summary;
        if (min_lon < max_min_lon) max_min_lon = min_lon;
        if (max_lon > max_max_lon) max_max_lon = max_lon;
        if (max_lat > max_max_lat) max_max_lat = max_lat;
        if (min_lat < max_min_lat) max_min_lat = min_lat;
      });

      const bounds = new mapboxgl.LngLatBounds(
        new mapboxgl.LngLat(max_min_lon, max_min_lat),
        new mapboxgl.LngLat(max_max_lon, max_max_lat)
      );

      this.map?.fitBounds(bounds, {
        padding: {
          top: sectorAnalysisIsActive ? 100 : 200,
          bottom: sectorAnalysisIsActive ? 100 : 200,
          right: sectorAnalysisIsActive ? 100 : 200,
          /** Слева распологается сам контрол, поэтому есть смещение  */
          left: sectorAnalysisIsActive ? 1250 : 450,
        },
        linear: true,
        maxDuration: 300,
      });
    }
  }

  public cursorDefault() {
    if (this.map) this.map.getCanvas().style.cursor = "";
  }

  public updatePoints() {
    const {
      router: { points, path, isActive, costing },
    } = this.store.getState();

    this.removePoints();

    const positions = costing === RouteCosting.Template ? path : points;

    const pointsWithCoords = positions.filter((el) => el.coor);
    const mainPoints = positions.filter((el) => el.isMain);
    const intermediatePoints = positions.filter((el) => !el.isMain);

    if (pointsWithCoords.length && isActive) {
      if (costing !== RouteCosting.Template) {
        mainPoints.forEach((item, index) => {
          if (!item.coor) return;
          const keyIcon = getImage.iconImageKeys[index];
          const layer = new RoutePointLayer(this.map, `route-point-${keyIcon}`);
          getImage.addImageToMap(index, this.map, positions);

          const geojson = {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: item.coor?.toArray(),
                },
                properties: {
                  id: item.key,
                },
              },
            ],
          };
          layer.update(geojson);
          this.pointLayers.push(layer);
        });
      }

      intermediatePoints.forEach((item) => {
        const intermediateDragLayer = new RouteIntermediatePointLayer(
          this.map,
          `${ROUTE_INTERMEDIATE_POINT}-${item.key}`,
          item.key
        );

        this.pointLayers.push(intermediateDragLayer);
        intermediateDragLayer.update(item.coor!.toArray());
      });
    }
  }

  public updateRouteTimeVariant() {
    const {
      router: { routeTimeVariant },
    } = this.store.getState();

    if (routeTimeVariant && routeTimeVariant.paintedPath) {
      this.removeRoute();
      this.routeTimeVariant.update(routeTimeVariant.paintedPath);
      this.routeTimeVariantStroke.update();
    } else this.updateRoute();
  }

  public updateIntermediatePointsCoordinates() {
    const {
      router: { points, path, costing, routeVariants, activeIndexRoute },
    } = this.store.getState();

    const positions = costing === RouteCosting.Template ? path : points;

    if (routeVariants) {
      const activeVariant = routeVariants[activeIndexRoute];
      const pointsCoordiantes = decodeShape(activeVariant.original.trip.legs[0].shape);
      const line = turf.lineString(pointsCoordiantes);

      positions.forEach((el) => {
        if (!el.isMain) {
          const point = turf.point(el.coor!.toArray());
          const layer = this.pointLayers.find((item) => item.layerId === `${ROUTE_INTERMEDIATE_POINT}-${el.key}`);

          if (layer) {
            const {
              // @ts-ignore
              geometry: { coordinates },
            } = turf.nearestPointOnLine(line, point);

            layer.update(coordinates);
          }
        }
      });
    }
  }

  public removePoints() {
    this.pointLayers.forEach((layer) => {
      layer.remove();
    });
    this.pointLayers = [];
  }

  public setVisibilitySimulatedDtp(value: boolean) {
    this.simulatedDtpLayer.setVisibility(value);
  }

  private getMetroRoute = async (data: GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>) => {
    // const state = this.store.getState().router;
    // @note сделать кеширование всей этой темы через state
    const metroCollection = data.features.filter((feature) => feature.properties?.travel_type === "metro");

    const routeIds = metroCollection.reduce<number[]>((acc, feature) => {
      const routeId = feature.properties?.transit_info?.route_id;
      if (!routeId) return acc;
      if (acc.includes(routeId)) return acc;
      return [...acc, routeId];
    }, []);

    const promises = routeIds.map((routeId) => {
      return RouterAPI.router.getRouteInfoById(routeId);
    });

    const routeInfos = await Promise.all<RouteState["routeInfos"][0]>(promises);
    const metroWithColor = metroCollection.map((feature) => {
      if (!feature.properties) return feature;
      const routeInfo = routeInfos.find((info) => info.id === String(feature.properties?.transit_info?.route_id));
      feature.properties.color = routeInfo?.colour ?? "gray";
      return feature;
    });
    this.store.dispatch(Store.routerSlice.actions.setRouteInfos(routeInfos));
    return {
      type: "FeatureCollection" as const,
      features: metroWithColor,
    };
  };

  public setUnitsCountColor = (feature: GeoJSON.Feature<GeoJSON.LineString, RouteProperties>) => {
    const properties = { ...feature.properties };
    properties.unitsCountColor = shared.unitsCountDiagram.unitsCountColor["-1"];
    const _feature = { ...feature, properties };

    if (!this.unitsCountDiagramParams) return _feature;

    _feature.properties.unitsCountColor = shared.unitsCountDiagram.getColoByUnitsCount(
      this.unitsCountDiagramParams,
      _feature.properties.unitsCount
    );

    return _feature;
  };

  public updateRoute = (needBound = false) => {
    const {
      router: {
        routeVariants,
        activeIndexRoute,
        isActive,
        costing,
        isRouteDiagram,
        yandexVariant,
        isCompareWithYandex,
        isShowYandexRoute,
      },
    } = this.store.getState();

    if (routeVariants && routeVariants?.length > 0 && isActive) {
      if (isCompareWithYandex && yandexVariant && isShowYandexRoute) {
        this.routeYandexLayer.update({
          ...yandexVariant.route.path,
          features: yandexVariant.route.path.features,
        });
        this.routeYandexStrokeLayer.update();
      }

      if (isCompareWithYandex && yandexVariant && !isShowYandexRoute) {
        this.routeYandexStrokeLayer.remove();
        this.routeYandexLayer.remove();
      }

      routeVariants.forEach(async (variant, index) => {
        if (costing === RouteCosting.Auto || costing === RouteCosting.Cargo || costing === RouteCosting.Template) {
          if (index === activeIndexRoute && variant.paintedPath && variant.trafficLights) {
            const withUnitsCountColor = {
              ...variant.paintedPath,
              features: variant.paintedPath.features.map(this.setUnitsCountColor),
            };
            this.routeLayer.update(withUnitsCountColor);
            this.routeStrokeLayer.update();
            this.routeTlsLayer.update(variant.trafficLights);
          } else {
            const { shape } = variant.original.trip.legs[0];
            const data = shapeToGeoJson(shape, index);
            this.routeAltLayer.update(data);
            this.routeAltStrokeLayer.update();
          }
        } else if (costing === RouteCosting.Multimodal) {
          const data = getBusRouteGeometry(variant);
          const busCollection = {
            type: "FeatureCollection" as const,
            features: data.features.filter((feature) => feature.properties?.travel_type === "bus"),
          };
          const footCollection = {
            type: "FeatureCollection" as const,
            features: data.features.filter((feature) => feature.properties?.travel_type === "foot"),
          };

          const metroCollection = await this.getMetroRoute(data);

          this.routeBusLayer.update(busCollection);
          this.routeBusPedestrianLayer.update(footCollection);
          this.routeMetroLayer.update(metroCollection);
        } else if (costing === RouteCosting.Pedestrian) {
          const data = getBusRouteGeometry(variant);
          this.routePedestrianLayer.update(data);
        }
      });
      if (needBound) this.boundMap();

      this.setIsRouteDiagram(isRouteDiagram);
    } else {
      this.removeRoute();
    }
  };

  public setIsRouteLengthError = (value: boolean, length?: number) => {
    this.isRouteLengthError = value;

    if (!value) {
      this.routeLengthErrorPopup.remove();
    }

    if (this.routeLengthErrorPopup.isOpen()) return;
    if (typeof length !== "number") return;

    const container = document.createElement("div");
    container.className = "route-length-error-popup-container";
    this.routeLengthErrorPopup.setDOMContent(container);
    const metres = Math.trunc(length * 1000);
    const content = `
            <span class="route-length-error-popup-container__header">
                Построение маршрута длиной менее 50м не будет выполнено.
            </span>
            <span class="route-length-error-popup-container__description">
                Расстояние между точками <span>${metres}м</span>
            </span>
        `;
    container.innerHTML = content;
    const coordinates: [number, number] = [...this.pointLayers].pop()?.data?.features[0]?.geometry?.coordinates;

    if (!Array.isArray(coordinates) || !coordinates.length) return;

    !this.routeLengthErrorPopup.isOpen() &&
      this.routeLengthErrorPopup
        .setOffset([0, -500 / this.map.getZoom()])
        .setLngLat(coordinates)
        .addTo(this.map);
  };

  public setIsUnitsCountVisibility = (value: boolean) => {
    this.isUnitsCountVisibility = value;
    this.routeLayer.setIsUnitsCountVisibility(this.isUnitsCountVisibility);
  };

  public setUnitsCountDiagramParams = (params: RouteMapController["unitsCountDiagramParams"]) => {
    this.unitsCountDiagramParams = params;
    this.updateRoute();
  };

  public setIsSimulatedDtp = (value: boolean) => {
    this.isSimulatedDtp = value;
  };

  public setSimulatedDtp = (value: RouteMapController["simulatedDtp"]) => {
    this.simulatedDtp = value;
    if (!value) {
      this.routeSimulatedDtpPopup.remove();
      return this.simulatedDtpLayer.setData([]);
    }

    this.simulatedDtpLayer.setData([
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: value,
        },
        properties: {},
      },
    ]);
  };

  public removeRoute() {
    this.setIsRouteDiagram(false);
    this.routeStrokeLayer?.remove();
    this.routeAltStrokeLayer?.remove();
    this.routeLayer?.remove();
    this.routeAltLayer?.remove();
    this.routeTlsLayer?.remove();
    this.routeBusLayer?.remove();
    this.routeMetroLayer?.remove();
    this.routeBusPedestrianLayer?.remove();
    this.routePedestrianLayer?.remove();
  }
}

export default RouteMapController;
