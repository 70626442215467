import { TitledProps } from "./circle-marker.types";
import * as Markup from "./circle-marker.styles";
import { CircleMarker } from "./circle-marker";

export const TitledCircleMarker = (props: TitledProps) => (
  <Markup.Wrapper className={props.className}>
    <Markup.MarkerWrapper>
      <CircleMarker color={props.color} radius={8} />
    </Markup.MarkerWrapper>
    <div>{props.title}</div>
  </Markup.Wrapper>
);
