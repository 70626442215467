import React, { memo } from "react";
import { useValues, useCallbacks } from "./traffic-score-checkbox.hooks";
import { TemplateCheckbox } from "../template-checkbox";
import { RouteTrafficIcon } from "assets";
import "../template-checkbox/template-checkbox.scss";

const routeTrafficIcon = <RouteTrafficIcon />;

export const TrafficScoreCheckbox = memo(() => {
  const { isShowTrafficScore } = useValues();
  const { handleIsShowTrafficScoreChange } = useCallbacks();

  return (
    <TemplateCheckbox
      icon={routeTrafficIcon}
      id={"traffic-score-checkbox"}
      title={""}
      label={"Балл пробок по маршруту"}
      checked={isShowTrafficScore}
      onChange={handleIsShowTrafficScoreChange}
    />
  );
});
