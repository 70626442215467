import { GraphStatistic, State } from "./graph-compare.types";
import moment from "moment";
import { v1 as uuidv1 } from "uuid";
import { GraphStatisticsApiResponseType } from "../../api/graph-statistics/graph-statistics.types";

export const setIsVisible = (state: State, payload: State["isVisible"]): State => ({ ...state, isVisible: payload });

export const deleteVersion = (state: State, payload: GraphStatistic["id"]): State => ({
  ...state,
  graphVersions: state.graphVersions.filter((version) => version.id !== payload),
});

export const setDateOfVersion = (state: State, payload: Pick<GraphStatistic, "id" | "date">): State => ({
  ...state,
  graphVersions: state.graphVersions.map((version) =>
    version.id === payload.id ? { ...version, date: payload.date, isLoading: true, hasRequested: false } : version
  ),
});

export const createVersion = (state: State): State => ({
  ...state,
  selectedHistoryIndex: 1,
  graphVersions: [
    { id: String(uuidv1()), date: moment().toISOString(), isLoading: true, hasRequested: false },
    ...state.graphVersions,
  ],
});

export const setSelectedIndex = (state: State, selectedHistoryIndex: State["selectedHistoryIndex"]): State => ({
  ...state,
  selectedHistoryIndex,
});

export const addVersion = (state: State, payload?: GraphStatisticsApiResponseType) => ({
  ...state,
  graphVersions: state.graphVersions.map((item) =>
    item.date === payload?.date
      ? {
          ...item,
          isLoading: false,
          date: payload.date,
          lengthRotes: Math.round(payload.roadsNetworkLength),
          edgesCount: payload.edgesCount,
          vertexesCount: payload.nodesCount,
        }
      : item
  ),
});

export const addHistoryVersion = (state: State, payload: State["historyVersions"]): State => {
  return {
    ...state,
    historyVersions: payload,
  };
};

export const setSelectedGraphTypes = (state: State, payload: Partial<State["selectedGraphTypes"]>): State => ({
  ...state,
  graphVersions: state.graphVersions.map((item) => ({ ...item, isLoading: true, hasRequested: false })),
  selectedGraphTypes: {
    ...state.selectedGraphTypes,
    ...payload,
  },
});

export const setIsRequested = (state: State, payload: Pick<GraphStatistic, "id" | "hasRequested">): State => ({
  ...state,
  graphVersions: state.graphVersions.map((version) =>
    version.id === payload.id ? { ...version, hasRequested: payload.hasRequested } : version
  ),
});
