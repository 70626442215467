import _ from "lodash";
import { preciseNumber } from "utils/precise-number";
import * as Types from "./sector-analysis-card.types";
// @ts-ignore
import prettyNum from "pretty-num";

const isNumber = (value?: any): value is number => _.isNumber(value) && _.isFinite(value);

export const getPercent = (value: number, total: number): number => {
  if (value > 0 && total > 0) {
    const precisedSpeed = preciseNumber(value);
    const precisedFreeFlowSpeed = preciseNumber(total);
    const percent = (precisedSpeed / precisedFreeFlowSpeed) * 100;
    return preciseNumber(Number(percent));
  }

  return 0;
};

export const formatNumber = (value: number | null | undefined, units?: string) => {
  if (typeof value === "undefined" || value === null) return "н/д";
  const unit = units === "%" ? units : ` ${units}`;
  return `${prettyNum(preciseNumber(value), { thousandsSeparator: " ", decimalSeparator: "," })}${
    units ? `${unit}` : ""
  }`;
};

export const formatSpeed = (value?: number) => {
  return formatNumber(value, "км/ч");
};

export const formatTime = (value?: number) => {
  if (typeof value !== "number") return "н/д";
  const time = value / 60;
  const precisedTime = preciseNumber(time);
  if (Number(precisedTime) === 0) return "н/д";
  return formatNumber(precisedTime, "мин");
};

export const deviation = (value?: number, total?: number) => {
  if (!isNumber(value) || !isNumber(total) || value === total) return;
  const precisionPercent = getPercent(value, total);
  if (precisionPercent) {
    return `(${precisionPercent}%)`;
  }
};

export const timePercent = (time?: number, freeFlowTime?: number) => {
  if (
    isNumber(time) &&
    isNumber(freeFlowTime) &&
    time > 0 &&
    freeFlowTime > 0 &&
    preciseNumber(freeFlowTime / 60) !== preciseNumber(time / 60)
  ) {
    const precisionPercent = getPercent(time, freeFlowTime);
    return Number(precisionPercent) >= 0 ? `(+${precisionPercent}%)` : `(${precisionPercent}%)`;
  }
};

export const getTitle = (type: Types.AnalysisCardType) => {
  switch (type) {
    case "speed":
      return "Скорость";
    case "route":
      return "Маршруты НГПТ";
    case "time":
      return "Время проезда";
    case "utilization":
      return "Загруженность дороги";
    case "occ":
      return "Занятость маршрута";
    case "volume":
      return "Интенсивн. движения";
    case "density":
      return "Плотность потока";
    default:
      return "";
  }
};
