import React, { useState } from "react";
import { GraphCompareHeader, GraphCompareBody, GraphCompareFooter } from "./components";
import { useStoreContext } from "store";

import "./graph-compare.scss";

export const GraphCompare = () => {
  const [isGraphOpen, setIsGraphOpen] = useState(true);
  const { store } = useStoreContext();
  const isVisible = store.graphCompare.hooks.useIsVisible();

  if (!isVisible) {
    return null;
  }

  return (
    <div className="graph-compare-container">
      <GraphCompareHeader isGraphOpen={isGraphOpen} setIsGraphOpen={setIsGraphOpen} />
      <GraphCompareBody isGraphOpen={isGraphOpen} />
      <GraphCompareFooter />
    </div>
  );
};
