import styled from "styled-components";

export const Wrapper = styled.div`
  border-top: 1px solid var(--separator-color-default);
  display: flex;
  width: 100%;
  padding: 5px 5px 5px 19px;
  gap: 10px;

  div {
    line-height: 24px;
  }
`;
