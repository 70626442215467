import React, { memo } from "react";
import { ReactComponent as CompareIcon } from "assets/icons/favorite.svg";
import { AnalysisData } from "features/sector-analysis/types";
import "./sector-analysis-compare.scss";
import { AnalysisTotal } from "api/router/model/analysisResponse";

interface Props {
  current: AnalysisData;
  compare: AnalysisData;
}

const calcDifferencePercentage = (current: number | undefined, compare: number | undefined) => {
  if (typeof current !== "number" || typeof compare !== "number") return "Нет данных";
  if (current === compare) return "без изменений";
  const val = 100 - Math.floor((compare / current) * 100);
  return `${val > 0 ? "+" : ""}${val}%`;
};

const select = (analysis: AnalysisData, key: keyof AnalysisTotal) => analysis.total?.[key];
const selectUnits = (props: Props, key: keyof AnalysisTotal): [number | undefined, number | undefined] => [
  select(props.compare, key),
  select(props.current, key),
];
const getValue = (props: Props, key: keyof AnalysisTotal) => calcDifferencePercentage(...selectUnits(props, key));

export const Compare = memo((props: Props) => {
  const speed = getValue(props, "speed");
  const time = getValue(props, "time");
  const occ = getValue(props, "occ");
  const utilization = getValue(props, "utilization");
  const volume = getValue(props, "volume");

  const renderRow = (title: string, value: string) => (
    <div className="sector-analysis-card-row">
      <span className="sector-analysis-card-name">{title}</span>
      <span className="sector-analysis-card-value">{value}</span>
    </div>
  );

  return (
    <div className="sector-analysis-card sector-analysis-compare">
      <div className="sector-analysis-card-header">
        <CompareIcon />
        <span className="sector-analysis-card-title">Сравнение средних</span>
      </div>
      {renderRow("Средняя скорость", speed)}
      {renderRow("Среднее время проезда", time)}
      {renderRow("Средняя интенсивн. движения", occ)}
      {renderRow("Средняя занятость маршрута", utilization)}
      {renderRow("Средняя плотность потока", volume)}
    </div>
  );
});
