import moment from "moment";

type MaybeDate = Date | string | number;
type TimeUnitGetter = (date: Date) => number;
const locale = "ru-RU";

// const hours: TimeUnitGetter = (d: Date) => d.getHours();
// const minutes: TimeUnitGetter = (d: Date) => d.getMinutes();
export const day: TimeUnitGetter = (d: Date) => d.getDate();
export const year: TimeUnitGetter = (d: Date) => d.getFullYear();
export const month: TimeUnitGetter = (d: Date) => d.getMonth();

export const toDate = (date: MaybeDate): Date => {
  if (date instanceof Date) {
    return date;
  }
  const dateInstance = new Date(date);

  if (dateInstance.toString() === "Invalid Date") {
    throw new Error(`"${date}" cannot be converted to Date`);
  }

  return dateInstance;
};

export const isDaySame = (date: MaybeDate, test: MaybeDate) => {
  const current = toDate(date);
  const testDate = toDate(test);
  const comparators: TimeUnitGetter[] = [year, month, day];

  const compare = (now: Date, test: Date) => (getter: TimeUnitGetter) => getter(now) === getter(test);

  return comparators.map(compare(current, testDate)).every(Boolean);
};

export const isToday = (date: MaybeDate) => {
  const now = new Date();
  return isDaySame(now, date);
};

/**
 * @param format - строка для форматирования даты {@link https://momentjs.com/docs/#/parsing/string-format/}
 */
export const formatDate = (format?: string) => (value: MaybeDate) => {
  return moment(toDate(value)).format(format);
};

export const getMonthName = (date: MaybeDate): string => {
  try {
    const d = toDate(date);
    const formatter = new Intl.DateTimeFormat(locale, { month: "long" });
    return formatter.format(d);
  } catch {
    return "";
  }
};
