import React from "react";
import { Row } from "../row";
import * as utils from "../../sector-analysis-card.utils";
import * as Types from "../../sector-analysis-card.types";

export const Volume = (props: Omit<Types.VolumeCard, "type">) => {
  return (
    <>
      <Row
        title="Средняя"
        value={utils.formatNumber(props.volume, "ед./ч")}
        deviation={utils.deviation(props.volume, props.freeFlowVolume)}
      />
      <Row title="Без пробок" value={utils.formatNumber(props.freeFlowVolume, "ед./ч")} />
    </>
  );
};
