import React from "react";
import { useStoreContext } from "store";
import { VisibilityState } from "utils/hooks";

export const usePedestrianGraphVisibility = (): VisibilityState & { isDisabled: boolean } => {
  const { store } = useStoreContext();
  const isVisible = store.pedestrianGraph.hooks.useIsVisible();
  const isDisabled = store.pedestrianGraph.hooks.useIsDisabled();

  const toggle = React.useCallback(() => {
    store.pedestrianGraph.events.handleIsVisible(!isVisible);
  }, [isVisible]);
  const show = React.useCallback(() => {
    store.pedestrianGraph.events.handleIsVisible(true);
  }, []);
  const hide = React.useCallback(() => {
    store.pedestrianGraph.events.handleIsVisible(false);
  }, []);

  return { isVisible, toggle, isDisabled, hide, show };
};
