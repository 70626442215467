import React from "react";
import { Icons } from "assets";
import { formatDate } from "utils/date-utils";
import * as Types from "../version-picker.types";
import * as consts from "../version-picker.consts";

export const Input = (props: Types.InputProps) => {
  return (
    <div className={consts.inputClass} onClick={props.onClick} tabIndex={-1}>
      <input
        type="text"
        value={props.value ? formatDate(consts.DATE_FORMAT)(props.value) : "Выберите версию"}
        readOnly
      />
      <Icons.SeparatorIcon />
    </div>
  );
};
