import React from "react";
import * as consts from "../../sector-analysis-card.consts";
import * as utils from "../../sector-analysis-card.utils";
import * as Types from "../../sector-analysis-card.types";

export const Time = (props: Omit<Types.TimeCard, "type">) => {
  const { freeFlowTime, time } = props;
  return (
    <>
      <div className={`${consts.baseClass}-row`}>
        <span className={`${consts.baseClass}-name`}>Среднее</span>
        <div className={`${consts.baseClass}-value`}>
          <div>
            <span className={`${consts.baseClass}-average`}>{utils.formatTime(time)}</span>
            <span className={`${consts.baseClass}-deviation`}>{utils.timePercent(time, freeFlowTime)}</span>
          </div>
          {/* <span className={`${consts.baseClass}-range`}>({range})</span> */}
        </div>
      </div>
      <div className={`${consts.baseClass}-row`}>
        <span className={`${consts.baseClass}-name`}>Без пробок</span>
        <span className={`${consts.baseClass}-value`}>{utils.formatTime(freeFlowTime)}</span>
      </div>
    </>
  );
};
