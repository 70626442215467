import { Middleware } from "redux";
import axios, { AxiosInstance } from "axios";
import { GState } from "documentations";
import { APP_ENV } from "app-env";

let rootInstance: AxiosInstance;
let trafficInstance: AxiosInstance;
let routerInstance: AxiosInstance;
let warehouseInstance: AxiosInstance;

export const getInstance = () => rootInstance;
export const getTrafficInstance = () => trafficInstance;
export const getRouterInstance = () => routerInstance;
export const getWarehouseInstance = () => warehouseInstance;

export const setInstance = (token: string) => {
  rootInstance = axios.create({
    baseURL: APP_ENV.REACT_APP_DOMAIN_API,
    headers: { Authorization: `Bearer ${token}` },
  });
  trafficInstance = axios.create({
    baseURL: APP_ENV.REACT_APP_DTM_TRAFFIC_API,
    headers: { Authorization: `Bearer ${token}` },
  });
  routerInstance = axios.create({
    baseURL: APP_ENV.REACT_APP_DTM_ROUTER_API,
    headers: { Authorization: `Bearer ${token}` },
  });
  warehouseInstance = axios.create({
    baseURL: APP_ENV.REACT_APP_DTM_WAREHOUSE_API,
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const apiMiddleWare: Middleware<any, GState> = (store) => (next) => (action) => {
  if (action.type === "redux-oidc/USER_FOUND") {
    setInstance(action.payload.access_token);
  }
  return next(action);
};

export * from "./transport-hubs";
export * from "./administrative-division";
export * from "./travel-heatmap";
export * from "./traffic";
export * from "./bus-stop";
export * from "./zone-statistics";
export * from "./graph-statistics";
export * from "./cdn";
export * from "./detector-data";
export * from "./graph-versions";
