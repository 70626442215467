import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { render, unmountComponentAtNode } from "react-dom";
import cn from "classnames";
import { FullscreenButton } from "components";
import { GState } from "documentations";
import {
  AnalysisData,
  AnalysisDiagramCallbackParams,
  AnalysisDiagramOptions,
  AnalysisGraphRange,
  AnalysisType,
  DiagramEvent,
} from "features/sector-analysis/types";
import { AnalysisDiagramData, AnalysisGraph, AnalysisTotal } from "api/router/model/analysisResponse";
import * as observer from "observer";
import { sectorAnalysisSlice } from "../../store";
import { GraphLegend } from "../graph-legend";
import { SectorAnalysisTooltip } from "../sector-analysis-tooltip";
import { DetailsTooltip } from "../sector-details-tooltip";
import { useSectorAnalyseContext } from "../sector-analysis";
import { AnalysisDiagramController, CachedCell, Dispatcher, SectorAnalysisDiagram3D } from "./analysis-diagram";

import "rc-slider/assets/index.css";

const baseClass = "sector-analysis-diagram";
const fullscreenBaseClass = `${baseClass}__fullscreen`;
const diagramContainer3dBaseClass = `${baseClass}__diagram_3d`;

const tooltips = {
  [AnalysisType.current]: document.createElement("div"),
  [AnalysisType.compare]: document.createElement("div"),
};

tooltips[AnalysisType.current].className = "analysis-tooltip";
tooltips[AnalysisType.compare].className = "analysis-tooltip";

document.body.append(tooltips[AnalysisType.current], tooltips[AnalysisType.compare]);

let previousRow: number | null = null;
let previousSegmentOpacity = 0.7;
let previousUnitsCountFilter: [number, number] | null = null;
let previousAppearance: DiagramAppearance;

export interface SectorAnalysisDiagramProps {
  type: AnalysisType;
  data: AnalysisDiagramData | null;
  isShowEvents?: boolean;
  dateKeys: Array<string>;
  period: number;
  graphData: AnalysisGraph | null;
  graphRange: AnalysisGraphRange | null;
  compareWith: AnalysisData;
  total: AnalysisTotal | null;
}

const useGraphVisibilityOptions = () => {
  return useSelector(
    (state: GState) => ({
      isShowAverageSpeed: state.sectorAnalysis.isShowAverageSpeed,
      isShowAverageTime: state.sectorAnalysis.isShowAverageTime,
      isShowAverageVolume: state.sectorAnalysis.isShowAverageVolume,
      isShowAverageDensity: state.sectorAnalysis.isShowAverageDensity,
      isShowEvents: state.sectorAnalysis.isShowEvents,
      isShowTrafficScore: state.sectorAnalysis.isShowTrafficScore,
      isShowGlobalTrafficScore: state.sectorAnalysis.isShowGlobalTrafficScore,
      isShowTrafficIndex: state.sectorAnalysis.isShowTrafficIndex,
      isShowGlobalTrafficIndex: state.sectorAnalysis.isShowGlobalTrafficIndex,
      isUnitsCountGraph: state.sectorAnalysis.isUnitsCountGraph,
      isUnitsCountDiagram: state.sectorAnalysis.isUnitsCountDiagram,
    }),
    shallowEqual
  );
};
const useGetCurrentDate = () =>
  useSelector(
    (state: GState) => ({
      currentDateFrom: state.sectorAnalysis.current.dateFrom,
      currentDateTo: state.sectorAnalysis.current.dateTo,
    }),
    shallowEqual
  );
const useGetCompareDate = () =>
  useSelector(
    (state: GState) => ({
      compareDateFrom: state.sectorAnalysis.compare.dateFrom,
      compareDateTo: state.sectorAnalysis.compare.dateTo,
    }),
    shallowEqual
  );
const useGetIsActiveHeatmap = () => useSelector((state: GState) => state.sectorAnalysis.isActiveHeatmap, shallowEqual);
const useGetTrafficLights = () =>
  useSelector((state: GState) => {
    const { activeIndexRoute, routeVariants } = state.router;
    if (!routeVariants) return;
    const route = routeVariants[activeIndexRoute];
    if (route) return route.trafficLights?.features.map((el) => el.properties);
  }, shallowEqual);
const useGetTemplateId = () => useSelector((store: GState): number | string => store.router.templateId, shallowEqual);
const useIsCompare = () => useSelector((store: GState) => store.sectorAnalysis.isShowCompare, shallowEqual);
const useIsDiagramDisabled = () => useSelector((store: GState) => store.sectorAnalysis.isDiagramDisabled);
const useIsUnitsCountDiagram = () => useSelector((store: GState) => store.sectorAnalysis.isUnitsCountDiagram);
const useUnitsCountDiagramParams = () =>
  useSelector((store: GState) => store.sectorAnalysis.unitsCountDiagramParams, shallowEqual);
const useIsVolumetric = () => useSelector((store: GState) => store.sectorAnalysis.isVolumetric);
const useIsVolumetricFullscreen = () => useSelector((store: GState) => store.sectorAnalysis.isVolumetricFullscreen);
const useSegmentsOpacity = () => useSelector((store: GState) => store.sectorAnalysis.segmentsOpacity);
const useMinMaxUnitsCount3DFilter = () => useSelector((store: GState) => store.sectorAnalysis.minMaxUnitsCount3DFilter);
const useUseGraphFlags = () =>
  useSelector(
    (store: GState) => ({
      isShowAverageSpeed: store.sectorAnalysis.isShowAverageSpeed,
      isShowAverageTime: store.sectorAnalysis.isShowAverageTime,
      isShowGlobalTrafficScore: store.sectorAnalysis.isShowGlobalTrafficScore,
      isShowTrafficScore: store.sectorAnalysis.isShowTrafficScore,
      isUnitsCountGraph: store.sectorAnalysis.isUnitsCountGraph,
    }),
    shallowEqual
  );

const SectorAnalysisDiagram: React.FC<SectorAnalysisDiagramProps> = React.memo((props) => {
  const { type, data, total, dateKeys, period, graphData, graphRange, compareWith, isShowEvents } = props;
  const diagramCanvasRef3D = useRef<HTMLCanvasElement>(null);
  /** Статус видено/скрыто окно с деталями сравнения */
  const isOpenDetailTooltip = useRef<boolean>(false);
  /** Статус видено/скрыто окно с описанием ячейки */
  const isVisibleTooltip = useRef<boolean>(false);
  /** идентификатор таймера задержки для открытия описанием ячейки */
  const popupShowTimeout = useRef<NodeJS.Timeout>();
  /** Объект управления отрисовкой сетки */
  const chart = useRef<AnalysisDiagramController>();
  const diagramController3DRef = useRef<SectorAnalysisDiagram3D>();
  const diagramDispatcher = new Dispatcher();
  const { currentDateFrom, currentDateTo } = useGetCurrentDate();
  const { compareDateFrom, compareDateTo } = useGetCompareDate();
  const { isShowAverageSpeed, isShowTrafficScore, isShowAverageTime, isShowGlobalTrafficScore, isUnitsCountGraph } =
    useUseGraphFlags();
  const isActiveHeatmap = useGetIsActiveHeatmap();
  const trafficLights = useGetTrafficLights();
  const templateId = useGetTemplateId();
  const isCompare = useIsCompare();
  const { settingSpeedColor } = useSectorAnalyseContext();
  const isDiagramDisabled = useIsDiagramDisabled();
  const isUnitsCountDiagram = useIsUnitsCountDiagram();
  const unitsCountDiagramParams = useUnitsCountDiagramParams();
  const graphVisibilityOptions = useGraphVisibilityOptions();
  const isVolumetric = useIsVolumetric();
  const isVolumetricFullscreen = useIsVolumetricFullscreen();
  const segmentsOpacity = useSegmentsOpacity();
  const minMaxUnitsCount3DFilter = useMinMaxUnitsCount3DFilter();
  const fullscreenContainerClass = cn(fullscreenBaseClass, {
    [`${fullscreenBaseClass}_full`]: isVolumetricFullscreen,
  });
  const diagramContainer3dClass = cn(`${baseClass}__diagram ${diagramContainer3dBaseClass}`, {
    [`${diagramContainer3dBaseClass}_fullscreen`]: isVolumetricFullscreen,
  });
  const dispatch = useDispatch();

  const options = useMemo(() => {
    return {
      type,
      data,
      total,
      period,
      dateKeys,
      graphData,
      graphRange,
      compareWith,
      trafficLights,
      isUnitsCountDiagram,
      appearance: settingSpeedColor.appearance,
      visibility: graphVisibilityOptions,
    };
  }, [
    type,
    data,
    total,
    period,
    dateKeys,
    graphData,
    isCompare,
    graphRange,
    compareWith,
    isVolumetric,
    trafficLights,
    isUnitsCountDiagram,
    graphVisibilityOptions,
    settingSpeedColor.appearance,
  ]);

  const handleFullScreenClick = useCallback(() => {
    dispatch(sectorAnalysisSlice.actions.setIsVolumetricFullscreen(!isVolumetricFullscreen));
  }, [isVolumetricFullscreen, dispatch]);

  const handleClose = useCallback(
    (event?: React.MouseEvent<HTMLButtonElement>) => {
      event?.preventDefault();
      isOpenDetailTooltip.current = false;
      isVisibleTooltip.current = false;
      observer.dispatch(observer.EVENTS.ON_DETAILS_POPUP_CLOSE);
      observer.dispatch(observer.EVENTS.DIAGRAM_LEAVE, "any");
      unmountComponentAtNode(tooltips[type]);
    },
    [type, isOpenDetailTooltip, isVisibleTooltip]
  );

  // #region Events

  /** Обработка выхода за пределы сетки */
  const handleLeave = useCallback(() => {
    if (popupShowTimeout.current) clearTimeout(popupShowTimeout.current);
    if (isOpenDetailTooltip.current) handleClose();
    isVisibleTooltip.current = false;
    isOpenDetailTooltip.current = false;
    unmountComponentAtNode(tooltips[AnalysisType.current]);
    unmountComponentAtNode(tooltips[AnalysisType.compare]);
    tooltips[AnalysisType.current].style.display = "none";
    tooltips[AnalysisType.compare].style.display = "none";
  }, [isVisibleTooltip, isOpenDetailTooltip, popupShowTimeout, handleClose]);

  // #endregion

  const renderDetailTooltip = useCallback(
    (column: number, row: number) => {
      isOpenDetailTooltip.current = true;

      render(
        <DetailsTooltip
          currentDateFrom={currentDateFrom}
          currentDateTo={currentDateTo}
          compareDateFrom={compareDateFrom}
          compareDateTo={compareDateTo}
          templateId={templateId}
          type={type}
          data={data}
          compare={compareWith.diagram}
          dateKeys={dateKeys}
          column={column}
          row={row}
          onCloseClick={handleClose}
        />,
        tooltips[type]
      );
    },
    [
      currentDateFrom,
      currentDateTo,
      compareDateFrom,
      compareDateTo,
      isOpenDetailTooltip,
      templateId,
      type,
      data,
      compareWith,
      dateKeys,
      handleClose,
    ]
  );

  const renderDiagramTooltip = useCallback(
    (column: number, row: number, pos: any) => {
      const columnKey = dateKeys[column];
      const dataRow = data && data.rows[row];
      const item = dataRow && dataRow.cells ? dataRow.cells[columnKey] : null;
      const speed = dataRow?.freeFlowSpeed;
      render(
        <SectorAnalysisTooltip
          type="diagram"
          dataPointIndex={column}
          averageSpeed={item?.speed}
          occ={item?.occ}
          utilization={item?.utilization}
          freeFlowSpeed={speed}
          vehicleCount={item?.unitsCount}
          isShowCompare={isCompare}
          dateKeys={dateKeys}
          incidents={item?.incidents}
          pos={pos}
        />,
        tooltips[type]
      );
    },
    [data, dateKeys, isCompare, type, handleLeave]
  );

  const showDiagramTooltip = useCallback(
    (
      { type, column, row, rectangleX, rectangleY }: AnalysisDiagramCallbackParams,
      event: DiagramEvent | string = `${DiagramEvent.MOUSE_MOVE}${type}`
    ) => {
      if (popupShowTimeout.current) clearTimeout(popupShowTimeout.current);
      if (isOpenDetailTooltip.current) return;
      if (typeof row !== "number" || typeof rectangleX !== "number" || typeof rectangleY !== "number") return;

      const diagramId = `${baseClass}-${type}-diagram`;
      const diagramElement = document.getElementById(diagramId);
      const bound = diagramElement?.getBoundingClientRect() ?? diagramCanvasRef3D.current?.getBoundingClientRect();

      if (!bound) return;

      const { left, top } = bound;

      popupShowTimeout.current = setTimeout(() => {
        if (isOpenDetailTooltip.current) return;

        const leftStyle = left + rectangleX - 110;
        const topStyle = top + rectangleY - 130;
        const validType = type as AnalysisType;

        tooltips[validType].style.display = "block";
        tooltips[validType].style.position = "fixed";
        tooltips[validType].style.left = `${leftStyle}px`;
        tooltips[validType].style.top = `${topStyle}px`;

        if (event === DiagramEvent.DBL_CLICK) {
          return renderDetailTooltip(column, row);
        }

        isVisibleTooltip.current = true;
        return renderDiagramTooltip(column, row, {
          left,
          top,
          rectangleX,
          rectangleY,
        });
      }, 200);
    },
    [popupShowTimeout, renderDiagramTooltip, renderDetailTooltip]
  );

  const onDbClickDiagram = useCallback(
    (args: any) => {
      if (!isCompare) return;
      handleLeave();
      showDiagramTooltip(args, DiagramEvent.DBL_CLICK);
    },
    [isCompare, showDiagramTooltip, handleLeave]
  );

  const onMoveDiagram = useCallback(
    (args) => {
      const { row } = args;
      if (isCompare && isOpenDetailTooltip.current) return;
      if (!isOpenDetailTooltip.current) handleLeave();
      showDiagramTooltip(args);

      if (row === previousRow) return;
      observer.dispatch(observer.EVENTS.SECTOR_ANALYSIS_MAP_DRAW_PATH, row);
      previousRow = row;
    },
    [isCompare, isOpenDetailTooltip, showDiagramTooltip, handleLeave]
  );

  const renderGraphTooltip = useCallback(
    (column: number) => {
      if (!graphData) return;

      const rowKey = column === dateKeys.length ? dateKeys[0] : dateKeys[column];
      const item = data && graphData.points[rowKey];
      const tooltip = (
        <SectorAnalysisTooltip
          type="graph"
          averageSpeed={item?.speed}
          timeTravel={item?.time}
          dataPointIndex={column}
          dateKeys={dateKeys}
        />
      );

      render(tooltip, tooltips[AnalysisType.current]);
      render(tooltip, tooltips[AnalysisType.compare]);
    },
    [data, dateKeys, graphData]
  );

  const prepareWrapper = useCallback((type: AnalysisType, rectangleX: number) => {
    const diagramId = `${baseClass}-${type}-diagram`;
    const diagramElement = document.getElementById(diagramId);
    const bound = diagramElement?.getBoundingClientRect();

    if (!bound) return;

    const { left, top } = bound;
    const tooltipWidth = 220;
    const baseTop = top + 20;
    const topStyle = baseTop;
    const leftStyle = left + rectangleX - tooltipWidth / 2;

    tooltips[type].style.display = "block";
    tooltips[type].style.position = "fixed";
    tooltips[type].style.left = `${leftStyle}px`;
    tooltips[type].style.top = `${topStyle}px`;
  }, []);

  const handleGraphClick = useCallback(
    ({ column, rectangleX }) => {
      prepareWrapper(AnalysisType.current, rectangleX);
      prepareWrapper(AnalysisType.compare, rectangleX);
      renderGraphTooltip(column);
    },
    [renderGraphTooltip, prepareWrapper]
  );

  const handleIsFilterDisabledChanged = useCallback(
    (value: boolean) => {
      dispatch(sectorAnalysisSlice.actions.setIsFiltersDisabled(value));
    },
    [dispatch]
  );

  const subscribe = useCallback(() => {
    diagramDispatcher.on(DiagramEvent.ZOOM, handleLeave);
    diagramDispatcher.on(DiagramEvent.SCROLL, handleLeave);
    diagramDispatcher.on(DiagramEvent.DBL_CLICK, onDbClickDiagram);
    diagramDispatcher.on(`${DiagramEvent.MOUSE_MOVE}${type}`, onMoveDiagram);
    diagramDispatcher.on(DiagramEvent.LEAVE, handleLeave);
    diagramDispatcher.on(DiagramEvent.LEAVE_DIAGRAM, handleLeave);
    diagramDispatcher.on(DiagramEvent.MOUSE_OUT, handleLeave);
    diagramDispatcher.on(DiagramEvent.GRAPH_CLICK, handleGraphClick);
    diagramDispatcher.on(DiagramEvent.LEAVE_GRAPH, handleLeave);
    diagramDispatcher.on(DiagramEvent.SET_IS_FILTER_DISABLED, handleIsFilterDisabledChanged);
    diagramDispatcher.on(DiagramEvent.HIDE_DIAGRAM_POPUPS, handleLeave);
  }, [
    type,
    diagramDispatcher,
    onDbClickDiagram,
    onMoveDiagram,
    handleLeave,
    handleGraphClick,
    handleIsFilterDisabledChanged,
  ]);

  const unsubscribe = useCallback(() => {
    diagramDispatcher.off(DiagramEvent.ZOOM, handleLeave);
    diagramDispatcher.off(DiagramEvent.SCROLL, handleLeave);
    diagramDispatcher.off(DiagramEvent.DBL_CLICK, onDbClickDiagram);
    diagramDispatcher.off(`${DiagramEvent.MOUSE_MOVE}${type}`, onMoveDiagram);
    diagramDispatcher.off(DiagramEvent.LEAVE, handleLeave);
    diagramDispatcher.off(DiagramEvent.LEAVE_DIAGRAM, handleLeave);
    diagramDispatcher.off(DiagramEvent.MOUSE_OUT, handleLeave);
    diagramDispatcher.off(DiagramEvent.GRAPH_CLICK, handleGraphClick);
    diagramDispatcher.off(DiagramEvent.LEAVE_GRAPH, handleLeave);
    diagramDispatcher.off(DiagramEvent.SET_IS_FILTER_DISABLED, handleIsFilterDisabledChanged);
    diagramDispatcher.off(DiagramEvent.HIDE_DIAGRAM_POPUPS, handleLeave);
  }, [
    type,
    diagramDispatcher,
    onDbClickDiagram,
    handleLeave,
    onMoveDiagram,
    handleGraphClick,
    handleIsFilterDisabledChanged,
  ]);

  const createChart = useCallback(
    (options: AnalysisDiagramOptions) => {
      chart.current = new AnalysisDiagramController(
        {
          graph: document.querySelector(`#${baseClass}-${type}-graph`),
          diagram: document.querySelector(`#${baseClass}-${type}-diagram`),
        },
        options
      );
      subscribe();
      chart.current.updateAppearance(settingSpeedColor.appearance);
      chart.current.setIsCompare(isCompare);
    },
    [type, isCompare, subscribe]
  );

  const handleResize = useCallback(() => {
    chart?.current?.destroy();
    unsubscribe();
    createChart(options);
    subscribe();
    chart?.current?.draw();
  }, [options, subscribe, unsubscribe, createChart]);

  useEffect(() => {
    observer.on(observer.EVENTS.DIAGRAM_LEAVE, handleLeave);
    observer.on(observer.EVENTS.WINDOW_RESIZE, handleResize);

    return () => {
      observer.off(observer.EVENTS.DIAGRAM_LEAVE, handleLeave);
      observer.off(observer.EVENTS.WINDOW_RESIZE, handleResize);
    };
  }, [handleLeave, handleResize]);

  useEffect(() => {
    const updateAppearance = (appearance: DiagramAppearance) => {
      chart.current?.updateAppearance(appearance);
      diagramController3DRef.current
        ?.setOpacity(previousSegmentOpacity)
        .setAppearance(appearance)
        .setUnitsCountFilter(previousUnitsCountFilter);
      previousAppearance = appearance;
    };

    settingSpeedColor.on(settingSpeedColor.events.update, updateAppearance);

    return () => {
      settingSpeedColor.off(settingSpeedColor.events.update, updateAppearance);
    };
  }, [chart, settingSpeedColor]);

  useEffect(() => {
    if (!chart.current) {
      createChart(options);
      subscribe();
    }

    unsubscribe();
    chart.current?.setOptions(options, isCompare);
    chart.current?.updateAppearance(settingSpeedColor.appearance);
    chart.current?.setUnitsCountDiagramParams(unitsCountDiagramParams);
    subscribe();

    return () => {
      unsubscribe();
      chart.current?.destroy();
    };
  }, [isVolumetric, type, isCompare, options, subscribe, unsubscribe, onMoveDiagram, createChart, settingSpeedColor]);

  useEffect(() => {
    chart?.current?.setIsShowEvents(isShowEvents);
  }, [isShowEvents]);

  useEffect(() => {
    chart.current?.setIsCompare(isCompare);
  }, [isCompare]);

  useEffect(() => {
    chart.current?.setIsDisabled(isDiagramDisabled);
  }, [isDiagramDisabled]);

  useEffect(() => {
    if (type === AnalysisType.current) return;

    const isActiveHeatmapFlag = isCompare && isActiveHeatmap && type === AnalysisType.compare;
    chart.current?.setIsActiveHeaMap(isActiveHeatmapFlag);
  }, [type, isCompare, isActiveHeatmap]);

  useEffect(() => {
    chart.current?.setUnitsCountDiagramParams(unitsCountDiagramParams);
  }, [unitsCountDiagramParams]);

  useEffect(() => {
    diagramController3DRef.current?.setOpacity(segmentsOpacity).setAppearance(previousAppearance);
    previousSegmentOpacity = segmentsOpacity;
  }, [segmentsOpacity]);

  useEffect(() => {
    diagramController3DRef.current?.resize();
  }, [isVolumetricFullscreen]);

  useEffect(() => {
    diagramController3DRef.current?.setUnitsCountFilter(minMaxUnitsCount3DFilter);
    previousUnitsCountFilter = minMaxUnitsCount3DFilter;
  }, [minMaxUnitsCount3DFilter]);

  const handleCellHover = (point: { x: number; y: number }, cellCache: CachedCell) => {
    showDiagramTooltip({
      type: AnalysisType.current,
      column: cellCache.columnIndex,
      row: cellCache.rowIndex,
      rectangleX: point.x,
      rectangleY: point.y,
    });
  };

  useEffect(() => {
    if (!diagramCanvasRef3D.current || !options.data) return;
    chart.current?.destroyDiagram();
    chart.current = undefined;
    diagramController3DRef.current?.off(diagramController3DRef.current.events.cellHover, handleCellHover);
    diagramController3DRef.current?.off(diagramController3DRef.current.events.cursorLeave, handleLeave);
    diagramController3DRef.current?.destroy();
    diagramController3DRef.current = new SectorAnalysisDiagram3D(diagramCanvasRef3D.current);
    diagramController3DRef.current.on(diagramController3DRef.current.events.cellHover, handleCellHover);
    diagramController3DRef.current.on(diagramController3DRef.current.events.cursorLeave, handleLeave);
    diagramController3DRef.current
      .setData(options.data)
      .setOpacity(previousSegmentOpacity)
      .setAppearance(previousAppearance)
      .setUnitsCountFilter(previousUnitsCountFilter);
  }, [isVolumetric, options.data]);

  return (
    <div className={baseClass}>
      <div id={`${baseClass}-${type}-graph`} className={`${baseClass}__graph`} />
      <GraphLegend
        isCityTraffic={isShowGlobalTrafficScore}
        isRouteTraffic={isShowTrafficScore}
        isAverageTime={isShowAverageTime}
        isAverageSpeed={isShowAverageSpeed}
        isUnitsCountDiagram={isUnitsCountDiagram}
        isUnitsCountGraph={isUnitsCountGraph}
      />
      {!isVolumetric && <div id={`${baseClass}-${type}-diagram`} className={`${baseClass}__diagram`} />}
      {isVolumetric && (
        <div className={diagramContainer3dClass}>
          <canvas ref={diagramCanvasRef3D} />
          <div className={fullscreenContainerClass}>
            <FullscreenButton isFullscreen={isVolumetricFullscreen} onClick={handleFullScreenClick} />
          </div>
        </div>
      )}
    </div>
  );
});

export { SectorAnalysisDiagram };
