import { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { setAllCheckBoxes } from "../../store/sector-analysis-slice";
import { UnitsCountIcon } from "assets";
import { LegendItem } from "./legend-item";
import { AverageSpeedCheckbox } from "../graph-legend/components/graph-legend-checkboxes/average-speed-checkbox";
import { AverageTimeCheckbox } from "../graph-legend/components/graph-legend-checkboxes/average-time-checkbox";
import { GlobalTrafficScoreCheckbox } from "../graph-legend/components/graph-legend-checkboxes/global-traffic-score-checkbox";
import { TrafficScoreCheckbox } from "../graph-legend/components/graph-legend-checkboxes/traffic-score-checkbox";
import "./graph-legend.scss";

type Props = {
  isCityTraffic: boolean;
  isRouteTraffic: boolean;
  isAverageTime: boolean;
  isAverageSpeed: boolean;
  isUnitsCountDiagram: boolean;
  isUnitsCountGraph: boolean;
};

const baseClass = "graph-legend";
const unitsCountIcon = <UnitsCountIcon />;

export const GraphLegend = memo<Props>((props) => {
  const dispatch = useDispatch();
  const [allCheckBoxesChecked, setAllCheckBoxesChecked] = useState(false);

  const handleToggleAllCheckBoxes = () => {
    const newValue = !allCheckBoxesChecked;
    setAllCheckBoxesChecked(newValue);
    dispatch(setAllCheckBoxes(newValue));
  };

  const notUnitsCountDiagramComponents = !props.isUnitsCountDiagram && (
    <>
      <button onClick={handleToggleAllCheckBoxes} className="button-all-graph-legend-checkboxes">
        Все
      </button>
      <TrafficScoreCheckbox />
      <GlobalTrafficScoreCheckbox />
      <AverageTimeCheckbox />
      <AverageSpeedCheckbox />
    </>
  );

  return (
    <div className={baseClass}>
      {notUnitsCountDiagramComponents}
      {props.isUnitsCountDiagram && props.isUnitsCountGraph && (
        <LegendItem icon={unitsCountIcon} title="Количество ТС на участке" />
      )}
    </div>
  );
});
