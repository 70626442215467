import styled from "styled-components";

export const TransportFlowBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 23px;
  padding: 18px 15px;
  max-height: 550px;
  overflow: auto;
  cursor: default;
`;

export const DataBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
`;

export const AvgFlowDataBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  div {
    flex-direction: column;
    gap: 5px;
    justify-content: flex-start;
  }
`;

export const TransportFlowItem = styled.div`
  display: flex;
  justify-content: end;

  .label {
    display: flex;
    flex-direction: row;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #747474;
    width-max: 163px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .value {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }

  .transport-flow-parameters-tag {
    margin-left: 3px;
  }
`;

export const RowsBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div<{ isNotBorderBottom?: boolean }>`
  display: flex;
  justify-content: space-between;
  height: 32px;
  align-items: center;
  border-bottom: ${(props) => (props.isNotBorderBottom ? "" : "1px solid #E0E0E0")};

  .label {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    color: #747474;
  }

  .lanes-parameters-tag {
    margin-left: 8px;
  }
`;

export const ValuesBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 9px;
  width: 54%;
  .value {
    width: 30px;
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
  }
`;
