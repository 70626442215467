import React from "react";
import { Row } from "../row";
import * as utils from "../../sector-analysis-card.utils";
import * as Types from "../../sector-analysis-card.types";

export const OCC = (props: Omit<Types.OCCCard, "type">) => {
  return (
    <>
      <Row
        title="Средняя"
        value={utils.formatNumber(props.occ, "%")}
        deviation={utils.deviation(props.occ, props.freeFlowOcc)}
      />
      <Row title="Без пробок" value={utils.formatNumber(props.freeFlowOcc, "%")} />
    </>
  );
};
