import React from "react";
import * as Types from "../../sector-analysis-card.types";
import * as consts from "../../sector-analysis-card.consts";

export const Route = (props: Omit<Types.RouteCard, "type">) => {
  return (
    <div className={`${consts.baseClass}-routes`}>
      {props.routes &&
        props.routes.map((el) => (
          <div className={`${consts.baseClass}-route`} key={el}>
            {el}
          </div>
        ))}
    </div>
  );
};
