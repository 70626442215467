import { APP_ENV } from "app-env";

export const overTime = (lastTime: string | undefined): boolean => {
  if (lastTime === undefined) return false;
  const fiveMunutes = 1000 * 60 * (APP_ENV.REACT_APP_CONFIGURATION?.detectorOverTimePeriod ?? 6);

  const d = new Date();

  const lastTimeAlert = new Date(lastTime);

  const isTimeOver = d.getTime() - lastTimeAlert.getTime() > fiveMunutes;

  return isTimeOver;
};
