import React from "react";
import { Icons } from "assets";
import { CircleMarker } from "components";
import * as Types from "./graph-compare-filter.types";
import { useCtrlGraphVisibility } from "features/ctrl-graph";
import { useCargoGraphVisibility } from "features/ctrl-graph/components";
import { usePedestrianGraphVisibility } from "features/pedestrian-graph";
import { VisibilityState } from "utils/hooks";
import { CargoGraphColor, PedestrianGraphConstants } from "map-controller/modules";

export const filterButtonsMeta: Types.GraphButtonMeta[] = [
  {
    icon: <Icons.SixDots />,
    title: "Дорожный граф",
  },
  {
    icon: <CircleMarker color={CargoGraphColor} radius={8} />,
    title: "Грузовой каркас",
  },
  {
    icon: <CircleMarker color={PedestrianGraphConstants.LineColor} radius={8} />,
    title: "Пешеходный граф",
  },
  {
    icon: <CircleMarker color="#00AAE1" radius={8} />,
    title: "Велосипедный граф",
  },
];

const createButtons = (meta: Types.GraphButtonMeta[], states: VisibilityState[]): Types.GraphButton[] => {
  const clickWrapper = (callback?: () => void) => (e: React.MouseEvent) => {
    e.stopPropagation();
    callback?.();
  };

  return meta.map((button, index) => ({
    ...button,
    onClick: clickWrapper(states[index]?.toggle),
    isActive: states[index]?.isVisible,
    isDisabled: !states[index],
  }));
};

export const useFilterButtons = () => {
  const ctrlGraph = useCtrlGraphVisibility();
  const cargo = useCargoGraphVisibility();
  const pedestrian = usePedestrianGraphVisibility();
  const states = [ctrlGraph, cargo, pedestrian];

  return createButtons(filterButtonsMeta, states);
};
