import React, { memo } from "react";
import { LegendItem } from "../../../../graph-legend/legend-item";
import "./template-checkbox.scss";

interface TemplateCheckboxProps {
  icon: React.ReactNode;
  label: string;
  title: string;
  id: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TemplateCheckbox = memo((props: TemplateCheckboxProps) => {
  const { icon, label, title, id, checked, onChange } = props;

  return (
    <>
      <LegendItem icon={icon} title={title} />
      <input className="container-active-focus-input" id={id} type="checkbox" checked={checked} onChange={onChange} />
      <label htmlFor={id} className="container-active-focus">
        {label}
      </label>
    </>
  );
});
