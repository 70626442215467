import { lightTheme } from "@megapolis/uikit/theme";
import { DefaultTheme } from "styled-components";

export const theme = (): DefaultTheme => {
  return {
    ...lightTheme,
    datePicker: {
      ...lightTheme.datePicker,
      day: {
        ...lightTheme.datePicker.day,
        active: {
          ...lightTheme.datePicker.day.active,
          background: "#62C400",
        },
      },
    },
  };
};
