import mapboxgl from "mapbox-gl";
import { preciseNumber } from "utils/precise-number";
import "./route-transport-count-popup.scss";

type PopupData = { volume?: number; unitsCount: number };
const popupClass = "route-transport-count-popup";

export class RouteTransportCountPopup {
  private popup: mapboxgl.Popup;
  private data: PopupData | null = null;

  constructor(private map: mapboxgl.Map) {
    this.popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      className: "route-transport-count-popup",
      maxWidth: "500px",
    });
  }

  setData(data: PopupData) {
    this.data = data;
    return this;
  }

  setLngLat(lngLat: mapboxgl.LngLatLike) {
    this.popup.setLngLat(lngLat);
    return this;
  }

  show() {
    this.popup.setHTML(this.createHTML()).addTo(this.map);
  }

  remove() {
    this.popup.remove();
  }

  private createHTML() {
    return `
      <div>
          <div class="${this.getDerivedClass("title")}">Параметры транспортного потока</div>
         ${this.createRow("Кол-во ТС за 5 мин, ед.", this.valToNumber(this.data?.unitsCount))}
         ${this.createRow("Кол-во ТС за час, ед.", this.valToNumber(this.data?.volume))}
      </div>
    `;
  }

  private getDerivedClass = (className: string) => {
    return `${popupClass}__${className}`;
  };

  private createRow = (title: string, value?: string | number) => {
    return `
      <div class="${this.getDerivedClass("row")}">
        <div class="${this.getDerivedClass("label")}">${title}</div>
        <div class="${this.getDerivedClass("value")}">${value || "н/д"}</div>
      </div>
    `;
  };

  private valToNumber = (value?: number | string) => {
    if (typeof value === "number") {
      return preciseNumber(value);
    }
    return value;
  };
}
