const getDiffBetweenTwoNumbers = (numberArr: Array<number | undefined>) => {
  const [one, two] = numberArr as number[];
  if (numberArr.includes(0)) {
    return 0;
  }
  return (((two - one) / one) * 100).toFixed(2);
};

const getParsDiff = (diff: number | null) => {
  if (diff === null || diff === 0) return "Без изменений";
  if (isNaN(diff)) return "";
  if (diff < 1) return "менее 1%";
  return diff && diff < 0 ? `${diff}%` : `+${diff}%`;
};

export const helpers = {
  getDiffBetweenTwoNumbers,
  getParsDiff,
};
