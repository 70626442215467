import React from "react";
import { AlertWithoutDataBlock, AlertWithoutDataComponent } from "../../detector-popup.styled";
import moment from "moment";
import { OrangeAlarmIcon } from "assets";
import { overTime } from "../../utils";
import { DetectorNoDataAlert } from "./detector-no-data-alert";

export const DetectorIsTimeOverAlert = (props: { lastTime?: string }) => {
  const { lastTime } = props;

  const isTimeOver = overTime(lastTime);

  if (lastTime === undefined) {
    return <DetectorNoDataAlert />;
  }

  return isTimeOver ? (
    <AlertWithoutDataBlock>
      <AlertWithoutDataComponent>
        <OrangeAlarmIcon className="warnings-icon" />
        <div className="last-data-active-block">
          Расчёт за {moment(lastTime).format("HH:mm")}. Актуальных данных нет
        </div>
      </AlertWithoutDataComponent>
    </AlertWithoutDataBlock>
  ) : null;
};
