import React from "react";

export interface VisibilityState {
  isVisible: boolean;
  show: () => void;
  hide: () => void;
  toggle: () => void;
}

export const useVisibility = (initialState?: boolean): VisibilityState => {
  const [isVisible, setIsVisible] = React.useState(initialState ?? false);

  const show = React.useCallback(() => setIsVisible(true), []);
  const hide = React.useCallback(() => setIsVisible(false), []);
  const toggle = React.useCallback(() => setIsVisible((prev) => !prev), []);

  return { isVisible, show, hide, toggle };
};
