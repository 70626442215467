import { LineDataType } from "../../../types";

export const flowItemLabels: Record<keyof LineDataType, string> = {
  avgSpeed: "Ср. скорость, км/ч",
  avgFreewaySpeed: "Скор. своб. дороги, км/ч",
  avgOcc: "Занятость дороги, %",
  avgVolume: "Интенсивность потока, ед/ч",
  avgUtilization: "Загруженность, %",
  avgDensity: "Плотность потока, ед/км",
  totalUnitsCount: "Количество ТС, ед",
  from: "Начальное время интервала активности ДТ",
  to: "Конечное время интервала активности ДТ",
};

export const arrayFlowItemLabels: { label: string; name: keyof LineDataType }[] = [
  {
    label: flowItemLabels.avgFreewaySpeed,
    name: "avgFreewaySpeed",
  },
  {
    label: flowItemLabels.avgSpeed,
    name: "avgSpeed",
  },
  {
    label: flowItemLabels.avgOcc,
    name: "avgOcc",
  },
  {
    label: flowItemLabels.avgVolume,
    name: "avgVolume",
  },
  {
    label: flowItemLabels.avgUtilization,
    name: "avgUtilization",
  },
  {
    label: flowItemLabels.avgDensity,
    name: "avgDensity",
  },
  {
    label: flowItemLabels.totalUnitsCount,
    name: "totalUnitsCount",
  },
];
