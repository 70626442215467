import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  color: var(--text-color-second);
`;

export const MarkerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  max-width: 24px;
  width: 24px;
  gap: 4px;
`;
