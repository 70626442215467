import React from "react";
import ReactDatePicker from "react-datepicker";
import { ru } from "date-fns/locale";
import { useDatePickerProps } from "./date-picker.hooks";
import * as Markup from "./date-picker.styles";
import { DatePickerProps } from "./date-picker.types";

/**
 * Форк из DatePicker из UIKit
 * */
const DatePickerComponent = (props: DatePickerProps) => {
  const {
    value,
    onChange,
    /* cspell:disable-next-line */
    placeholder = "ДД.ММ.ГГГГ",
    minDate,
    maxDate,
    excludeDates,
    hasError,
    isIconHidden,
    zIndex,
    customInput,
    isOpen,
    className,
    calendarClassName,
    dayClassName,
    timeClassName,
    weekDayClassName,
    monthClassName,
    wrapperClassName,
    ...inputProps
  } = props;

  const datePickerProps = useDatePickerProps(props);

  return (
    <Markup.Wrapper>
      <ReactDatePicker
        disabledKeyboardNavigation
        dateFormat="dd/MM/yyyy"
        locale={ru}
        selected={value}
        placeholderText={placeholder}
        disabled={inputProps.isDisabled}
        minDate={minDate}
        maxDate={maxDate}
        open={isOpen}
        excludeDates={excludeDates}
        ariaInvalid={hasError ? "true" : undefined}
        onChange={onChange}
        calendarClassName={calendarClassName}
        dayClassName={dayClassName}
        timeClassName={timeClassName}
        weekDayClassName={weekDayClassName}
        monthClassName={monthClassName}
        wrapperClassName={wrapperClassName}
        // todo: Какая-то хрень с MaskedInput (позже разобраться)
        // customInput={
        //   customInput ?? (
        //     <MaskedInput
        //       hasError={hasError}
        //       after={!isIconHidden && <Calendar24Icon />}
        //       mask={dateMask}
        //       {...inputProps}
        //     />
        //   )
        // }
        {...datePickerProps}
      />
    </Markup.Wrapper>
  );
};

export const DatePicker = React.memo(DatePickerComponent);
