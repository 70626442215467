import React, { useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { GState } from "documentations";
import { AnalysisData, AnalysisGraphRange, ChangeDateProps } from "features/sector-analysis/types";
import { Loader } from "components/loader";
import {
  SECTOR_ANALYSIS_GET_COMPARE_DATA,
  SECTOR_ANALYSIS_GET_CURRENT_DATA,
} from "features/sector-analysis/store/sector-analysis-middleware";
import { Tooltip } from "utils/components/tooltip/tooltip";
import { DayOfWeek } from "api/router/model/models";
import { RouteResponse } from "features/ctrl-route/types";
import { SectorAnalysisFilter } from "../sector-analysis-filter";
import { SectorAnalysisDiagram } from "../sector-analysis-diagram";
import * as consts from "../sector-analysis-diagram/analysis-diagram/utils/consts";
import { trafficType } from "../sector-analysis-appearance-menu/utils/consts";
import { sectorAnalysisSlice } from "features/sector-analysis/store/sector-analysis-slice";
import { SectorAnalysisDiagramLegend } from "../sector-analysis-diagram-legend";
import { SectorAnalysisUnitsCountLegend } from "../sector-analysis-units-count-legend";
import { Legend } from "../sector-analysis-diagram-legend/sector-analysis-diagram-legend";
import { useGetAppearance } from "../sector-analysis/context";

const tooltipLabel =
  'Ctrl+Scroll - масштаб, Shift+Scroll - вертикальное растягивание, "захват" курсором - навигация, Esc - отмена масштаба';

type Props = AnalysisData & {
  changeDate: (value: ChangeDateProps) => void;
  changeEs: (value: Array<number>) => void;
  changeIsShowEvent?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  changeDays: (value: Array<DayOfWeek>) => void;
  isShowEvents?: boolean;
  changeIsShowCompare?: () => void;
  isShowCompare: boolean;
  currentIsLoading?: boolean;
  dateKeys: Array<string>;
  graphRange: AnalysisGraphRange | null;
  period: number;
  routeVariants: Array<RouteResponse> | null;
  routeIsLoading: boolean;
  compareWith: AnalysisData;
};

const className = "sector-analysis";

const diagramAppearanceToLegend = (appearance: DiagramAppearance | null) => {
  if (!appearance) return {};

  return trafficType.reduce((acc: Legend, key, index) => {
    const from = appearance.find((item) => item.key === key);
    if (!from) return acc;

    let to = appearance[index + 1].value;
    if (index === appearance.length - 1) to = 150;

    return {
      ...acc,
      [key]: { from: from.value, to, color: consts.trafficColors[key] },
    };
  }, {});
};

export const SectorAnalysisContent: React.FC<Props> = ({
  dateFrom,
  dateTo,
  changeDate,
  es,
  changeEs,
  changeIsShowEvent,
  isShowEvents,
  changeIsShowCompare,
  isShowCompare,
  type,
  isLoading,
  days,
  changeDays,
  graph,
  diagram,
  total,
  currentIsLoading,
  dateKeys,
  graphRange,
  period,
  routeVariants,
  routeIsLoading,
  compareWith,
}) => {
  const dispatch = useDispatch();
  const appearance = useGetAppearance();
  const isZoomTooltip = useSelector((store: GState) => store.sectorAnalysis.isZoomTooltip);
  const isShowLegend = useSelector((store: GState) => store.sectorAnalysis.isShowLegend);
  const isUnitsCountDiagram = useSelector((state: GState) => state.sectorAnalysis.isUnitsCountDiagram);
  const unitsCountDiagramParams = useSelector(
    (state: GState) => state.sectorAnalysis.unitsCountDiagramParams,
    shallowEqual
  );

  const handleClose = useCallback(() => {
    dispatch(sectorAnalysisSlice.actions.setIsZoomTooltip(false));
  }, [dispatch]);

  useEffect(() => {
    if (type === "current" && (!diagram || !graph)) dispatch(SECTOR_ANALYSIS_GET_CURRENT_DATA());
    if (type === "compare" && isShowCompare && (!diagram || !graph || currentIsLoading))
      dispatch(SECTOR_ANALYSIS_GET_COMPARE_DATA());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (!routeVariants && !routeIsLoading) return <span className={`${className}-placeholder`}>Постройте маршрут</span>;

  const loaderClassName = cn(`${className}-loader`, {
    [`${className}-loader_compare`]: isShowCompare,
  });

  return (
    <div className={`${className}-content`}>
      <SectorAnalysisFilter
        changeIsShowEvent={changeIsShowEvent}
        isShowEvents={isShowEvents}
        dateFrom={dateFrom}
        dateTo={dateTo}
        changeDate={changeDate}
        es={es}
        changeEs={changeEs}
        changeIsShowCompare={changeIsShowCompare}
        isShowCompare={isShowCompare}
        type={type}
        days={days}
        changeDays={changeDays}
      />
      {(!diagram || !graph) && !isLoading ? (
        <div className={`${className}-placeholder`}>Нет данных</div>
      ) : (
        <>
          <SectorAnalysisDiagram
            type={type}
            total={total}
            data={diagram}
            period={period}
            graphData={graph}
            dateKeys={dateKeys}
            graphRange={graphRange}
            compareWith={compareWith}
            isShowEvents={isShowEvents}
          />
          {isZoomTooltip && type === "current" && (
            <div className={`${className}-tooltip-wrapper`}>
              <Tooltip label={tooltipLabel} isCloseIcon={true} onClose={handleClose} />
            </div>
          )}
          {isShowLegend && !isUnitsCountDiagram && type === "current" && (
            <div className="sector-analysis-legend">
              <SectorAnalysisDiagramLegend legend={diagramAppearanceToLegend(appearance)} />
            </div>
          )}
          {isShowLegend && isUnitsCountDiagram && type === "current" && (
            <div className="sector-analysis-legend">
              <SectorAnalysisUnitsCountLegend params={unitsCountDiagramParams} />
            </div>
          )}
        </>
      )}
      {(currentIsLoading || isLoading || routeIsLoading) && (
        <div className={loaderClassName}>
          <Loader isLarge />
        </div>
      )}
    </div>
  );
};
