import styled from "styled-components";

export const PopupContainer = styled.div<{ isFullMode: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.161);
  width: ${(props) => (props.isFullMode ? 560 : 320)}px;
`;

export const HeaderBlock = styled.div`
  padding-top: 15px;
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  height: 75px;
  border-bottom: 1px solid #e0e0e0;
`;

export const TitleWithIconBlock = styled.div`
  padding-left: 15px;
  display: flex;
  gap: 9px;
  width: 80%;
`;

export const HeaderTitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  padding-bottom: 15px;
`;
export const HeaderNameBlock = styled.div`
  display: flex;
  gap: 6px;

  .num {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  .name {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #747474;
  }
`;

export const HeaderAddress = styled.div<{ isFullMode: boolean }>`
  font-size: 11px;
  line-height: 16px;
  min-width: ${(props) => (props.isFullMode ? "430px" : "190px")};
  max-width: 100%;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #747474;
  min-height: 15px;
`;

export const ButtonsBlock = styled.div`
  display: flex;
  gap: 23px;
  align-items: center;
  svg {
    cursor: pointer;
  }
`;

export const FirstBlock = styled.div`
  position: relative;
  width: 16px;
  height: 18px;
  align-self: center;
`;

export const TransportFlowBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
  padding: 15px;
`;

export const TitleBlock = styled.div`
  display: flex;
  gap: 9px;
  align-items: center;

  svg {
    cursor: pointer;
  }

  .title {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }
  .title-light {
    font-size: 12px;
    line-height: 16px;
    display: flex;
  }

  .statistics-parameters-tag {
    margin-left: 8px;
  }

  .arrow-up {
    rotate: 180deg;
  }
`;

export const TransportFlowItemsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const TransportFlowItem = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 20px;

  .label {
    display: flex;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #747474;
  }

  .value {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }

  .transport-flow-mini-block-tag {
    margin-left: 8px;
  }
`;

export const ToggleButtonBlock = styled.div`
  padding: 16px 15px 4px 15px;
`;

export const Button = styled.div`
  max-width: 200px;
  background-color: #03284c1a;
  height: 16px;
  padding: 0 4px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  border: 0;
  color: #027bff;
  margin-left: 2.5px;
`;

export const AlertWithoutDataBlock = styled.div`
  display: flex;
  padding: 10px 11px 0px 11px;
  gap: 8px;
  width: 100%;
  height: 52px;
`;

export const AlertWithoutDataComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0px 8px 0px 0px;
  border-radius: 6px;
  border: 1px solid #03284c1a;
  gap: 8px;
  background-color: #fff7d499;

  .warnings-icon {
    width: 18px;
    height: 18px;
    margin: 0 0 0 10px;
    fill: #fe9400;
  }

  .last-data-active-block {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin: 0 0 0 3px;
  }

  .tooltipOnMap {
    height: 50px;
  }
`;
