import React from "react";
import * as Markup from "./graph-compare-footer.styles";
import { RoadGraphCategory, RoadGraphColors, RoadVerticesColor } from "map-controller/modules";
import { TitledCircleMarker } from "components";

const categories = Object.keys(RoadGraphCategory).filter((key) => isNaN(Number(key)));

const getColor = (key: any) => {
  return RoadGraphColors[RoadGraphCategory[key] as unknown as RoadGraphCategory];
};

export const GraphCompareFooter = () => {
  return (
    <Markup.Wrapper>
      {categories.map((category) => (
        <TitledCircleMarker key={category} color={getColor(category)} title={category} />
      ))}
      <TitledCircleMarker title="Nodes" color={RoadVerticesColor} />
    </Markup.Wrapper>
  );
};
