import React from "react";
import * as Types from "../version-picker.types";
import * as consts from "../version-picker.consts";
import { List } from "./list";
import { DatePicker } from "./date-picker";

export const Dropdown = (props: Types.DropdownProps) => {
  return (
    <div className={consts.dropdownClass}>
      <List {...props} />
      <DatePicker {...props} />
    </div>
  );
};
