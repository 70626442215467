import { getRouterInstance } from "api/index";
import * as Types from "./graph-versions.types";

const graphParams = (graphTypes?: Types.GraphTypes[]) => {
  if (graphTypes) {
    return `?${graphTypes.map((type) => `type=${type}`).join("&")}`;
  }

  return "";
};

export const getGraphVersions = async (graphTypes?: Types.GraphTypes[]): Promise<Types.GraphVersionResponse> => {
  const routerApi = getRouterInstance();
  const { data } = await routerApi.get(`graph/versions${graphParams(graphTypes)}`);
  return data;
};
