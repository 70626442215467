import { createEvent } from "effector";
import { GraphStatistic, State } from "./graph-compare.types";

const handleIsVisibleChange = createEvent<State["isVisible"]>();
const deleteVersionById = createEvent<GraphStatistic["id"]>();
const setDateOfVersion = createEvent<Pick<GraphStatistic, "id" | "date">>();
const createVersion = createEvent();
const setSelectedGraphTypes = createEvent<Partial<State["selectedGraphTypes"]>>();
const getInit = createEvent();
const setIsRequested = createEvent<Pick<GraphStatistic, "id" | "hasRequested">>();
const setSelectedHistoryIndex = createEvent<State["selectedHistoryIndex"]>();

export const events = {
  handleIsVisibleChange,
  deleteVersionById,
  setDateOfVersion,
  createVersion,
  getInit,
  setSelectedGraphTypes,
  setIsRequested,
  setSelectedHistoryIndex,
};

export type Events = typeof events;
