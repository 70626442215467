import { useStore, useEvent } from "effector-react";
import { store } from "./graph-compare";
import { effects } from "./graph-compare.effects";

const useIsVisible = () => useStore(store).isVisible;
const useGraphVersions = () => useStore(store).graphVersions;
const useSelectedHistoryIndex = () => useStore(store).selectedHistoryIndex;
const useGraphVersionsHistory = () => useStore(store).historyVersions;
const useIsVersionHistoryLoading = () => useStore(effects.fetchGraphVersions.pending);
// todo: разобраться с ошибкой ts
// @ts-ignore
const useFetchGraphVersions = () => useEvent(effects.fetchGraphVersions);
const useSelectedHistoryGraphDate = () => {
  const versions = useGraphVersions();
  const index = useSelectedHistoryIndex();
  return versions[index]?.date;
};

export const hooks = {
  useIsVisible,
  useGraphVersions,
  useGraphVersionsHistory,
  useIsVersionHistoryLoading,
  useFetchGraphVersions,
  useSelectedHistoryGraphDate,
  useSelectedHistoryIndex,
};

export type Hooks = typeof hooks;
