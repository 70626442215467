import React from "react";
import { DatePicker as Component } from "components";
import { isDaySame } from "utils";
import * as Types from "../version-picker.types";
import * as consts from "../version-picker.consts";
import * as utils from "../version-picker.utils";

export const DatePicker = (props: Types.DatePickerProps) => {
  const handleChange = (d: Date | null) => {
    props.onChange?.(d ? d.toISOString() : null);
  };

  const historyDates = React.useMemo(() => utils.toHistoryDates(props.historyVersions), [props.historyVersions]);

  const dayClassName = React.useCallback(
    (date: Date) => {
      if (props.selectedDates.some((current) => isDaySame(current, date))) {
        return `${consts.calendarClass}__day--selected`;
      }
      if (!historyDates.some((current) => isDaySame(current, date))) {
        return "react-datepicker__day--disabled";
      }
      return null;
    },
    [historyDates]
  );

  return (
    <div className={`${consts.calendarClass}`}>
      <Component
        wrapperClassName={`${consts.calendarClass}__input`}
        calendarClassName={`${consts.calendarClass}__calendar`}
        dayClassName={dayClassName}
        value={new Date()}
        maxDate={new Date()}
        onChange={handleChange}
        isOpen
      />
    </div>
  );
};
