import React from "react";
import { Button } from "@megapolis/uikit";
import { useFilterButtons } from "./graph-compare.buttons";
import * as Markup from "./graph-compare-filters.styles";

export const GraphCompareFilters = React.memo(() => {
  const filterButtons = useFilterButtons();
  return (
    <Markup.Wrapper>
      {filterButtons.map((button) => {
        return (
          <Button
            key={button.title}
            content={Button.Content.IconText}
            icon={button.icon}
            text={button.title}
            onClick={button.onClick}
            isDisabled={button.isDisabled}
            priority={button.isActive ? "secondary" : "tertiary"}
          />
        );
      })}
    </Markup.Wrapper>
  );
});
